import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './ScabbardCraftingChallenge.css';

const ScabbardCraftingChallenge = ({ onComplete, difficulty }) => {
  const player = useSelector(state => state.player);
  const [boxes, setBoxes] = useState(Array(24).fill(false));
  const [sequence, setSequence] = useState([]);
  const [playerSequence, setPlayerSequence] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showingSequence, setShowingSequence] = useState(false);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [glowingIndexes, setGlowingIndexes] = useState([]);
  const [hiddenMessage, setHiddenMessage] = useState('');
  const [gameStarted, setGameStarted] = useState(false);

  const difficultySettings = {
    'Extra Hard': { sequenceLength: 12, rounds: 5 },
    'Hard': { sequenceLength: 8, rounds: 4 },
    'Medium': { sequenceLength: 5, rounds: 3 }
  };

  const mapDifficultyToSettingsKey = (translatedDifficulty) => {
    const lowerDifficulty = translatedDifficulty.toLowerCase();
    if (lowerDifficulty.includes('extra') || lowerDifficulty.includes('sehr')) return 'Extra Hard';
    if (lowerDifficulty.includes('hard') || lowerDifficulty.includes('schwer')) return 'Hard';
    return 'Medium';
  };

  const mappedDifficulty = mapDifficultyToSettingsKey(difficulty);

  const hiddenMessages = ['STOP', 'RUN', 'NO', 'WHY', 'HELP', 'LOOK'];

  const startGame = () => {
    setGameStarted(true);
    setScore(0);
    setGameOver(false);
    startRound();
  };

  const startRound = () => {
    const { sequenceLength } = difficultySettings[mappedDifficulty];
    const newSequence = Array(sequenceLength).fill().map(() => Math.floor(Math.random() * 24));
    setSequence(newSequence);
    setIsPlaying(true);
    setShowingSequence(true);
    showSequence(newSequence);
    setHiddenMessage(hiddenMessages[Math.floor(Math.random() * hiddenMessages.length)]);
    setPlayerSequence([]);
  };

  const showSequence = (seq) => {
    seq.forEach((boxIndex, i) => {
      setTimeout(() => {
        setBoxes(prev => {
          const newBoxes = [...prev];
          newBoxes[boxIndex] = true;
          return newBoxes;
        });
        setTimeout(() => {
          setBoxes(prev => {
            const newBoxes = [...prev];
            newBoxes[boxIndex] = false;
            return newBoxes;
          });
          if (i === seq.length - 1) {
            setShowingSequence(false);
          }
        }, 500);
      }, i * 1000);
    });
  };

  const handleBoxClick = (index) => {
    if (showingSequence || !isPlaying || gameOver) return;

    const newPlayerSequence = [...playerSequence, index];
    setPlayerSequence(newPlayerSequence);

    if (newPlayerSequence[newPlayerSequence.length - 1] !== sequence[newPlayerSequence.length - 1]) {
      endGame();
    } else {
      setGlowingIndexes(prev => [...prev, index]);
      setTimeout(() => {
        setGlowingIndexes(prev => prev.filter(i => i !== index));
      }, 1000);

      if (newPlayerSequence.length === sequence.length) {
        const newScore = score + 1;
        setScore(newScore);
        if (newScore === difficultySettings[mappedDifficulty].rounds) {
          endGame(true);
        } else {
          setTimeout(startRound, 1000);
        }
      }
    }
  };

  const endGame = (won = false) => {
    setGameOver(true);
    setIsPlaying(false);
    onComplete(score * 50);
  };

  const getHiddenMessageClass = (index) => {
    const messageLength = hiddenMessage.length;
    const startIndex = Math.floor(Math.random() * (24 - messageLength));
    if (index >= startIndex && index < startIndex + messageLength) {
      return `hidden-${hiddenMessage[index - startIndex].toLowerCase()}`;
    }
    return '';
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Scabbard Crafting Challenge</h2>
      {!gameStarted ? (
        <button onClick={startGame}>Start Game</button>
      ) : (
        <>
          <p>Score: {score}</p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridTemplateRows: 'repeat(6, 1fr)', gap: '5px', maxWidth: '400px', margin: 'auto' }}>
            {boxes.map((isLit, index) => (
              <div
                key={index}
                onClick={() => handleBoxClick(index)}
                className={`box ${isLit ? 'lit' : ''} ${glowingIndexes.includes(index) ? 'glow' : ''} ${getHiddenMessageClass(index)}`}
              />
            ))}
          </div>
          {gameOver && (
            <div>
              <p>Game Over! Final Score: {score * 50}</p>
              <p>{score === difficultySettings[mappedDifficulty].rounds ? "You've Won!" : "Keep practicing to improve your crafting skills."}</p>
              <button onClick={startGame}>Play Again</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ScabbardCraftingChallenge;