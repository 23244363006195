import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCoins, spendCoins, updateTotalScore } from '../../redux/playerSlice';
import FullScreenMobileModal from '../FullScreenMobileModal';
import EnteringTheTomb from './EnteringTheTomb';
import OreMining from './OreMining';
import SmeltingPuzzle from './SmeltingPuzzle';
import HammerTime from './HammerTime';
import SharpeningChallenge from './SharpeningChallenge';
import HiltCraftingPuzzle from './HiltCraftingPuzzle';
import ScabbardCraftingChallenge from './ScabbardCraftingChallenge';
import RuneMatchingGame from './RuneMatchingGame';
import WeaponTestingGame from './WeaponTestingGame';
import './miniindex.css';

const MiniGames = ({ onClose }) => {
  const { t } = useTranslation();
  const [currentGame, setCurrentGame] = useState(null);
  const [gameScore, setGameScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showGameOverModal, setShowGameOverModal] = useState(false);
  const [completedGames, setCompletedGames] = useState([]);
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [nextGameTime, setNextGameTime] = useState(null);
  const [timeUntilNextGame, setTimeUntilNextGame] = useState('');
  const [showRestartCard, setShowRestartCard] = useState(false);
  const player = useSelector(state => state.player);
  const totalScore = useSelector(state => state.player.totalScore);
  const dispatch = useDispatch();

  const REPLAY_COST = 50000; // Cost in coins to replay a game

  const games = [
    { 
      name: t('miniGames.enteringTheTomb.name'),
      component: EnteringTheTomb,
      image: "images/minigames/EnteringTheTomb.jpg",
      description: t('miniGames.enteringTheTomb.description'),
      difficultyType: 'experience'
    },
    { 
      name: t('miniGames.oreMining.name'),
      component: OreMining,
      image: "images/minigames/OreMining.jpg",
      description: t('miniGames.oreMining.description'),
      difficultyType: 'power'
    },
    { 
      name: t('miniGames.smeltingPuzzle.name'),
      component: SmeltingPuzzle,
      image: "images/minigames/SmeltingPuzzle.jpg",
      description: t('miniGames.smeltingPuzzle.description'),
      difficultyType: 'knowledge'
    },
    { 
      name: t('miniGames.hammerTime.name'),
      component: HammerTime,
      image: "images/minigames/HammerTime.jpg",
      description: t('miniGames.hammerTime.description'),
      difficultyType: 'power'
    },
    { 
      name: t('miniGames.sharpeningChallenge.name'),
      component: SharpeningChallenge,
      image: "images/minigames/sharpeningchallenge.jpg",
      description: t('miniGames.sharpeningChallenge.description'),
      difficultyType: 'experience'
    },
    { 
      name: t('miniGames.hiltCraftingPuzzle.name'),
      component: HiltCraftingPuzzle,
      image: "images/minigames/hilt-crafting-puzzle-image.png",
      description: t('miniGames.hiltCraftingPuzzle.description'),
      difficultyType: 'knowledge'
    },
    { 
      name: t('miniGames.scabbardCraftingChallenge.name'),
      component: ScabbardCraftingChallenge,
      image: "images/minigames/scabbard-crafting-challenge-image.jpg",
      description: t('miniGames.scabbardCraftingChallenge.description'),
      difficultyType: 'experience'
    },
    { 
      name: t('miniGames.runeMatching.name'),
      component: RuneMatchingGame,
      image: "images/minigames/rune-matching-game-image.jpg",
      description: t('miniGames.runeMatching.description'),
      difficultyType: 'knowledge'
    },
    { 
      name: t('miniGames.weaponTesting.name'),
      component: WeaponTestingGame,
      image: "images/minigames/weapon-testing-game-image.jpg",
      description: t('miniGames.weaponTesting.description'),
      difficultyType: 'power'
    },
  ];


  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('minigamesData')) || {};
    setCompletedGames(savedData.completedGames || []);
    setCurrentGameIndex(savedData.currentGameIndex || 0);
    setNextGameTime(savedData.nextGameTime ? new Date(savedData.nextGameTime) : null);
    setShowRestartCard(savedData.completedGames?.length === games.length);
  }, []);

  useEffect(() => {
    localStorage.setItem('minigamesData', JSON.stringify({
      completedGames,
      currentGameIndex,
      nextGameTime: nextGameTime?.toISOString(),
    }));
  }, [completedGames, currentGameIndex, nextGameTime]);

  useEffect(() => {
    let interval;
    if (nextGameTime) {
      interval = setInterval(() => {
        const now = new Date();
        if (now >= nextGameTime) {
          setNextGameTime(null);
          setTimeUntilNextGame('');
          clearInterval(interval);
        } else {
          const diff = nextGameTime.getTime() - now.getTime();
          const seconds = Math.ceil(diff / 1000);
          setTimeUntilNextGame(`${seconds}s`);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [nextGameTime]);

  const getDifficulty = (type) => {
    switch(type) {
      case 'power':
        if (player.power < 100) return t('miniGames.difficultyLevels.extraHard');
        if (player.power < 200) return t('miniGames.difficultyLevels.hard');
        return t('miniGames.difficultyLevels.medium');
      case 'experience':
        if (player.experience < 100) return t('miniGames.difficultyLevels.extraHard');
        if (player.experience < 200) return t('miniGames.difficultyLevels.hard');
        return t('miniGames.difficultyLevels.medium');
      case 'knowledge':
        if (player.knowledge < 200) return t('miniGames.difficultyLevels.extraHard');
        if (player.knowledge < 300) return t('miniGames.difficultyLevels.hard');
        return t('miniGames.difficultyLevels.medium');
      default:
        return t('miniGames.difficultyLevels.medium');
    }
  };

  const handleGameComplete = (gameName, score) => {
    setGameScore(score);
    setCurrentGame(null);
    setShowModal(false);
    setShowGameOverModal(true);
    
    const newTotalScore = (Number(totalScore) || 0) + (Number(score) || 0);
    dispatch(updateTotalScore(newTotalScore));
    
    let updatedCompletedGames = [...completedGames];
    if (!completedGames.includes(gameName)) {
      updatedCompletedGames.push(gameName);
    }
    setCompletedGames(updatedCompletedGames);
    
    if (updatedCompletedGames.length === games.length) {
      setShowRestartCard(true);
    } else {
      const nextTime = new Date();
      nextTime.setHours(nextTime.getHours() + 24); // Set cooldown to 24 hours
      setNextGameTime(nextTime);
      localStorage.setItem('nextGameTime', nextTime.toISOString());}
    
    const nextIndex = (currentGameIndex + 1) % games.length;
    setCurrentGameIndex(nextIndex);
  };

  const canPlay = !nextGameTime || new Date() >= nextGameTime;

  const startGame = (game) => {
    if (completedGames.includes(game.name)) {
      if (player.coins >= REPLAY_COST) {
        dispatch(spendCoins(REPLAY_COST));
        setCurrentGame(game);
        setShowModal(true);
      } else {
        alert(t('miniGames.notEnoughCoins'));
      }
    } else if (canPlay) {
      setCurrentGame(game);
      setShowModal(true);
    }
  };

  const closeGameOverModal = () => {
    setShowGameOverModal(false);
    setCurrentGame(null);
    setGameScore(0);
  };


    return (
      <div className="minigames-wrapper">
        <div className="minigames-content">
          <div className="minigames-header">
            <h2 className="minigames-title">{t('miniGames.title')}</h2>
            <div className="minigames-total-score">{t('miniGames.totalScore')}: {totalScore}</div>
          </div>
          {showRestartCard ? (
            <div className="minigames-restart-card">
              <h3>{t('miniGames.allGamesCompleted')}</h3>
              <p>{t('miniGames.restartDescription')}</p>
              <button 
                className="minigames-button"
                onClick={() => {
                  setCurrentGameIndex(0);
                  setNextGameTime(null);
                  setShowRestartCard(false);
                  setCompletedGames([]);
                }}
              >
                {t('miniGames.restartGames')}
              </button>
            </div>
          ) : (
            <div className="minigames-card">
              <img src={games[currentGameIndex].image} alt={games[currentGameIndex].name} className="minigames-card-image" />
              <h3 className="minigames-card-title">{games[currentGameIndex].name}</h3>
              <p className="minigames-card-description">{games[currentGameIndex].description}</p>
              <p className="minigames-card-description">
                {t('miniGames.difficulty')}: {getDifficulty(games[currentGameIndex].difficultyType)}
              </p>
              {!canPlay && timeUntilNextGame && (
                <p className="minigames-cooldown-text">
                  {t('miniGames.nextGameAvailable', { time: timeUntilNextGame })}
                </p>
              )}
              <button 
                className="minigames-button"
                onClick={() => startGame(games[currentGameIndex])}
                disabled={!canPlay && !completedGames.includes(games[currentGameIndex].name)}
              >
                {completedGames.includes(games[currentGameIndex].name)
                  ? t('miniGames.playForCoins', { cost: REPLAY_COST }) 
                  : t('miniGames.startGame')}
              </button>
            </div>
          )}
        <FullScreenMobileModal isOpen={showModal} onClose={() => setShowModal(false)}>
          {currentGame && (
            <currentGame.component
              game={currentGame}
              score={gameScore}
              updateScore={setGameScore}
              difficulty={getDifficulty(currentGame.difficultyType)}
              onComplete={(score) => handleGameComplete(currentGame.name, score)}
            />
          )}
        </FullScreenMobileModal>

        <FullScreenMobileModal isOpen={showGameOverModal} onClose={closeGameOverModal}>
          <div className="minigames-game-over-modal">
            <h2 className="minigames-game-over-title">{t('miniGames.gameOver')}</h2>
            <p className="minigames-game-over-text">{t('miniGames.gameScore', { score: gameScore })}</p>
            <p className="minigames-game-over-text">{t('miniGames.newTotalScore', { score: totalScore })}</p>
            <button onClick={closeGameOverModal} className="minigames-button">{t('miniGames.close')}</button>
          </div>
        </FullScreenMobileModal>
      </div>
    </div>
  );
};

export default MiniGames;