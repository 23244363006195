import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './RuneMatchingGame.css';

const RuneMatchingGame = ({ difficulty = 'Medium', onComplete }) => {
  const player = useSelector(state => state.player);
  const [grid, setGrid] = useState([]);
  const [selectedRunes, setSelectedRunes] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);

  const runeSymbols = ['ᚠ', 'ᚢ', 'ᚦ', 'ᚨ', 'ᚱ', 'ᚲ', 'ᚷ', 'ᚹ', 'ᚺ', 'ᚾ', 'ᛁ', 'ᛃ', 'ᛇ', 'ᛈ', 'ᛉ', 'ᛊ', 'ᛏ', 'ᛒ', 'ᛖ', 'ᛗ', 'ᛚ', 'ᛜ', 'ᛞ', 'ᛟ'];

  const difficultySettings = {
    'Extra Hard': { gridWidth: 6, gridHeight: 8, timeLimit: 45 },
    'Hard': { gridWidth: 6, gridHeight: 6, timeLimit: 60 },
    'Medium': { gridWidth: 4, gridHeight: 6, timeLimit: 90 }
  };

  const initializeGame = () => {
    const { gridWidth, gridHeight, timeLimit } = difficultySettings[difficulty] || difficultySettings['Medium'];
    const totalRunes = gridWidth * gridHeight;
    const runePool = [...runeSymbols.slice(0, totalRunes / 2), ...runeSymbols.slice(0, totalRunes / 2)];
    const shuffledRunes = shuffleArray(runePool);
    
    setGrid(shuffledRunes);
    setSelectedRunes([]);
    setMatchedPairs([]);
    setTimeLeft(timeLimit);
    setGameOver(false);
    setGameStarted(true);
  };

  useEffect(() => {
    if (gameStarted && timeLeft > 0 && !gameOver) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && gameStarted && !gameOver) {
      endGame();
    }
  }, [timeLeft, gameOver, gameStarted]);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleRuneClick = (index) => {
    if (gameOver || selectedRunes.includes(index) || matchedPairs.includes(index) || selectedRunes.length === 2) return;

    const newSelectedRunes = [...selectedRunes, index];
    setSelectedRunes(newSelectedRunes);

    if (newSelectedRunes.length === 2) {
      if (grid[newSelectedRunes[0]] === grid[newSelectedRunes[1]]) {
        const newMatchedPairs = [...matchedPairs, ...newSelectedRunes];
        setMatchedPairs(newMatchedPairs);
        if (newMatchedPairs.length === grid.length) {
          endGame(true);
        }
      }
      setTimeout(() => setSelectedRunes([]), 1000);
    }
  };

  const endGame = (won = false) => {
    setGameOver(true);
    const score = calculateScore(won);
    onComplete(score);
  };

  const calculateScore = (won) => {
    const baseScore = matchedPairs.length * 5 + timeLeft;
    const difficultyMultiplier = {
      'Extra Hard': 1.5,
      'Hard': 1.2,
      'Medium': 1
    };
    return Math.round(baseScore * (difficultyMultiplier[difficulty] || 1)) * (won ? 2 : 1);
  };

  const renderGrid = () => {
    const { gridWidth } = difficultySettings[difficulty] || difficultySettings['Medium'];
    return (
      <div className="game-grid" style={{ gridTemplateColumns: `repeat(${gridWidth}, 1fr)` }}>
        {grid.map((rune, index) => (
          <div
            key={index}
            onClick={() => handleRuneClick(index)}
            className={`rune-card ${matchedPairs.includes(index) ? 'matched' : ''} ${selectedRunes.includes(index) ? 'selected' : ''}`}
          >
            {selectedRunes.includes(index) || matchedPairs.includes(index) ? rune : '?'}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="rune-matching-game">
      <h2>Enchanting the Weapon: Rune Matching</h2>
      {!gameStarted ? (
        <button onClick={initializeGame}>Start Game</button>
      ) : (
        <>
          <div className="game-info">
            <p>Difficulty: {difficulty}</p>
            <p>Time Left: {timeLeft}s</p>
            <p>Matched Pairs: {matchedPairs.length / 2}</p>
          </div>
          {renderGrid()}
          {gameOver && (
            <div className="game-over">
              <p>Game Over!</p>
              <p>{matchedPairs.length === grid.length ? "You've won!" : "Keep practicing to improve your enchanting skills."}</p>
              <button className="play-again-btn" onClick={initializeGame}>Play Again</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RuneMatchingGame;