import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WebApp from '@twa-dev/sdk';
import { RefreshCw } from 'lucide-react';
import { addCoins } from '../redux/playerSlice';
import './ReferralSystem.css';

const REFERRAL_REWARD = 20;

const ReferralSystem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { referralRewardBonus } = useSelector((state) => state.player);

  const [referredFriends, setReferredFriends] = useState([]);
  const [claimedReferrals, setClaimedReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [error, setError] = useState(null);
  const [claimableCoins, setClaimableCoins] = useState(0);
  const [referralCount, setReferralCount] = useState(0);
  const [totalReferralReward, setTotalReferralReward] = useState(0);

  const telegramId = WebApp.initDataUnsafe?.user?.id || 0;
  const referralCode = telegramId + 13252081519;

  useEffect(() => {
    const storedClaimedReferrals = localStorage.getItem(`claimedReferrals_${telegramId}`);
    if (storedClaimedReferrals) {
      setClaimedReferrals(JSON.parse(storedClaimedReferrals));
    }
  }, [telegramId]);

  const fetchReferredFriends = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://stardb.game2mythos.workers.dev/referrals?code=${referralCode}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      
      if (response.ok) {
        const friends = Array.isArray(data.referredFriends) ? data.referredFriends : [];
        setReferredFriends(friends);
        setReferralCount(friends.length);
        
        const totalReward = Number(data.totalReferralReward) || 0;
        setTotalReferralReward(totalReward);
        
        const claimable = Math.max(0, totalReward - (claimedReferrals.length * REFERRAL_REWARD));
        setClaimableCoins(claimable);
      } else {
        setError(data.error || 'An error occurred');
      }
    } catch (err) {
      setError('Failed to fetch referred friends');
      console.error('Error fetching referred friends:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReferredFriends();
  }, [referralCode, referralRewardBonus, claimedReferrals]);

  const refreshReferredFriends = () => {
    fetchReferredFriends();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  const shareReferralLink = () => {
    try {
      const botUsername = 'GameMythos_bot';
      const referralLink = `https://t.me/${botUsername}?start=${referralCode}`;
      const message = t('social.referral.shareMessage', { code: referralCode, link: referralLink });
      WebApp.openTelegramLink(`https://t.me/share/url?url=${encodeURIComponent(message)}`);
    } catch (error) {
      console.error('Error sharing referral link:', error);
      WebApp.showAlert(t('social.referral.shareError'));
    }
  };

  const handleClaimReward = () => {
    if (claimableCoins > 0) {
      dispatch(addCoins(claimableCoins));
      WebApp.showAlert(t('social.referral.rewardClaimed', { coins: claimableCoins }));
      
      const newClaimedReferrals = [...claimedReferrals, ...referredFriends];
      setClaimedReferrals(newClaimedReferrals);
      
      localStorage.setItem(`claimedReferrals_${telegramId}`, JSON.stringify(newClaimedReferrals));
      
      setClaimableCoins(0);
    } else {
      WebApp.showAlert(t('social.referral.noRewardsToday'));
    }
  };

  const getBonusPercentage = () => {
    return (referralRewardBonus * 100).toFixed(0) + '%';
  };

  return (
    <div className="referral-system">
      <h2 className="referral-system__header">{t('social.referral.title')}</h2>

      <div className="referral-system__code-section">
        <h3 className="referral-system__subheader">{t('social.referral.yourCode')}</h3>
        <div className="referral-system__code-display">
          <span className="referral-system__code">{referralCode}</span>
          <button className="referral-system__copy-btn" onClick={copyToClipboard}>
            {copySuccess ? t('social.referral.copied') : t('social.referral.copy')}
          </button>
        </div>
      </div>

      <div className="referral-system__actions">
        <button className="referral-system__share-btn" onClick={shareReferralLink}>
          {t('social.referral.shareLink')}
        </button>
      </div>

      <div className="referral-system__friends-section">
        <div className="referral-system__friends-header">
          <h3 className="referral-system__subheader">{t('social.referral.referredFriends')}</h3>
          <button
            className="referral-system__refresh-btn"
            onClick={refreshReferredFriends}
            disabled={isLoading}
          >
            <RefreshCw size={18} className={isLoading ? 'spin' : ''} />
          </button>
        </div>
        {isLoading ? (
          <p className="referral-system__loading">{t('social.referral.loading')}</p>
        ) : referredFriends.length > 0 ? (
          <ul className="referral-system__friend-list">
            {referredFriends.map((friend, index) => (
              <li key={index} className="referral-system__friend-item" style={{height: '50px'}}>
                <span className="referral-system__friend-name">{friend}</span>
                <span className="referral-system__friend-reward">
                  {claimedReferrals.includes(friend) ? t('social.referral.claimed') : `${REFERRAL_REWARD} ${t('general.coins')}`}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="referral-system__no-friends">{t('social.referral.noFriendsYet')}</p>
        )}
        {error && <p className="referral-system__error">{t('social.referral.error')} {error}</p>}
      </div>

      <div className="referral-system__claim-section">
        <button
          className="referral-system__claim-btn"
          onClick={handleClaimReward}
          disabled={claimableCoins === 0}
        >
          {t('social.referral.claimReward')} ({claimableCoins} {t('general.coins')})
        </button>
      </div>

      <div className="referral-system__stats">
        <p className="referral-system__stat-item">{t('social.referral.totalFriends')}: {referralCount}</p>
        <p className="referral-system__stat-item">{t('social.referral.totalRewards')}: {totalReferralReward} {t('general.coins')}</p>
        <p className="referral-system__stat-item">{t('social.referral.claimableRewards')}: {claimableCoins} {t('general.coins')}</p>
        <p className="referral-system__stat-item">{t('social.referral.bonusPercentage')}: {getBonusPercentage()}</p>
        <p className="referral-system__stat-item">{t('social.referral.rewardPerReferral')}: {Math.floor(REFERRAL_REWARD * (1 + referralRewardBonus))} {t('general.coins')}</p>
      </div>
    </div>
  );
};

export default ReferralSystem;