import React from 'react';
import './CustomAlert.css';

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        
        <div className="custom-alert-content">
          <p className="custom-alert-message">{message}</p>
          <button className="custom-alert-button" onClick={onClose}>
            Okay!
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;