class BoostService {
  constructor() {
    this.activeBoosts = {};
  }

  activateBoost(id, duration) {
    const expirationTime = Date.now() + duration * 60 * 60 * 1000; // Convert hours to milliseconds
    this.activeBoosts[id] = expirationTime;
    localStorage.setItem('activeBoosts', JSON.stringify(this.activeBoosts));
  }

  isBoostActive(id) {
    return this.activeBoosts[id] && this.activeBoosts[id] > Date.now();
  }

  getTimeRemaining(id) {
    if (!this.isBoostActive(id)) return 0;
    return Math.max(0, this.activeBoosts[id] - Date.now());
  }

  removeExpiredBoosts() {
    const currentTime = Date.now();
    let changed = false;
    Object.keys(this.activeBoosts).forEach(id => {
      if (this.activeBoosts[id] <= currentTime) {
        delete this.activeBoosts[id];
        changed = true;
      }
    });
    if (changed) {
      localStorage.setItem('activeBoosts', JSON.stringify(this.activeBoosts));
    }
  }

  loadBoosts() {
    const savedBoosts = localStorage.getItem('activeBoosts');
    if (savedBoosts) {
      this.activeBoosts = JSON.parse(savedBoosts);
    }
  }
}

export const boostService = new BoostService();