import React, { useState, useEffect,useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { spendCoins, spendItem, addItem, setSelectedLegendaryWeapon, updateTotalScore } from '../redux/playerSlice';
import { setAlertMessage } from '../redux/gameSlice';
import { advancedWeaponCraftingSystem, advancedWeaponTemplates } from '../utils/advancedWeaponSystem';
import { specialItems } from '../data/shopItems';
import MiniGames from './minigames/index';
import Modal from './Modal';
import './LegendWeaponsLvl4.css';

const LegendWeaponsLvl4 = ({ onStartActivity, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const selectedWeapon = useSelector((state) => state.player.selectedLegendaryWeapon);
  const totalScore = useSelector((state) => state.player.totalScore);
  const [showMiniGame, setShowMiniGame] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [nextGameTime, setNextGameTime] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [weaponName, setWeaponName] = useState('');
  const [finalWeaponClass, setFinalWeaponClass] = useState('');
  const [showNameModal, setShowNameModal] = useState(false);
  const [isGetWeaponButtonVisible, setIsGetWeaponButtonVisible] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const savedWeapon = localStorage.getItem('craftingWeapon');
    if (savedWeapon) {
      const parsedWeapon = JSON.parse(savedWeapon);
      dispatch(setSelectedLegendaryWeapon(parsedWeapon));
      setWeaponName(parsedWeapon.customName || '');
    }

    const savedNextGameTime = localStorage.getItem('nextGameTime');
    if (savedNextGameTime) {
      setNextGameTime(new Date(savedNextGameTime));
    }

    setInstructions(t('legendWeapons.instructions'));
  }, [dispatch, t]);

  const startCrafting = (templateName) => {
    const template = advancedWeaponTemplates.find(t => t.name === templateName);
    if (!template) return;

    const craftingCost = 1000000; // Example cost, adjust as needed

    if (selectedWeapon || player.items[templateName]) {
      setInstructions(t('legendWeapons.alreadyHaveWeapon'));
      return;
    }

    const hasMaterials = template.materials.every(m => (player.items[m] || 0) >= 1);

    if (!hasMaterials || player.coins < craftingCost) {
      setInstructions(t('legendWeapons.notEnoughResources', {
        templateName: template.name,
        materials: template.materials.map(m => `- 1 ${m}`).join('\n'),
        craftingCost,
        inventory: template.materials.map(m => `- ${player.items[m] || 0} ${m}`).join('\n'),
        playerCoins: player.coins
      }));
      setSelectedTemplateName(templateName);
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    dispatch(spendCoins(craftingCost));
    template.materials.forEach(m => dispatch(spendItem({ id: m, quantity: 1 })));

    setShowNameModal(true);
    dispatch(setSelectedLegendaryWeapon(template));
    dispatch(updateTotalScore(0));
  };

  const handleCraftArrows = () => {
    if (player.coins >= 10 && (player.items.Wood || 0) >= 2) {
      dispatch(spendCoins(10));
      dispatch(spendItem({ id: 'Wood', quantity: 2 }));
      dispatch(addItem({ id: 'Arrow', quantity: 10 }));
      dispatch(setAlertMessage(t('legendWeapons.arrowsCrafted')));
    } else {
      dispatch(setAlertMessage(t('legendWeapons.notEnoughResourcesForArrows')));
    }
  };
  useEffect(() => {
    // Check if the total score has reached the threshold to show the button
    if (totalScore >= 1000) {  // You can adjust this threshold as needed
      setIsGetWeaponButtonVisible(true);
    }
  }, [totalScore]);

  const handleNameWeapon = () => {
    if (weaponName.trim()) {
      const namedWeapon = { ...selectedWeapon, customName: weaponName.trim() };
      dispatch(setSelectedLegendaryWeapon(namedWeapon));
      localStorage.setItem('craftingWeapon', JSON.stringify(namedWeapon));
      setShowNameModal(false);
      setInstructions(t('legendWeapons.weaponNamed', { weaponName }));
    }
  };

  const applySpecialItemBoosts = (baseScore) => {
    let boostedScore = baseScore;
    let boostMessages = [];
    specialItems.forEach(item => {
      const itemCount = player.items[item.id] || 0;
      for (let i = 0; i < itemCount; i++) {
        const boost = Math.floor(Math.random() * (item.maxBoost - item.minBoost + 1)) + item.minBoost;
        boostedScore += boost;
        dispatch(spendItem({ id: item.id, quantity: 1 }));
        boostMessages.push(t('legendWeapons.itemBoost', { itemName: item.name, boost }));
      }
    });
    if (boostMessages.length > 0) {
      dispatch(setAlertMessage(boostMessages.join('\n')));
    }
    return boostedScore;
  };

  const handleMiniGameComplete = (gameResult) => {
    setShowMiniGame(false);
  
    const nextTime = new Date();
    nextTime.setDate(nextTime.getDate() + 1);
    setNextGameTime(nextTime);
    localStorage.setItem('nextGameTime', nextTime.toISOString());
  
    if (selectedWeapon) {
      const baseScore = gameResult.score;
      const boostedScore = applySpecialItemBoosts(baseScore);
      const newTotalScore = totalScore + boostedScore;
      dispatch(updateTotalScore(newTotalScore));
  
      setInstructions(t('legendWeapons.miniGameCompleted', {
        baseScore,
        boostedScore,
        weaponName,
        newTotalScore,
        currentClass: getWeaponClass(newTotalScore)
      }));
    }
  };

  const canPlayGame = () => {
    return !nextGameTime || new Date() >= nextGameTime;
  };

  const getWeaponClass = (score) => {
    if (score >= 10000) return t('legendWeapons.class.mythical');
    if (score >= 7500) return t('legendWeapons.class.legendary');
    if (score >= 5000) return t('legendWeapons.class.epic');
    if (score >= 1000) return t('legendWeapons.class.rare');
    return t('legendWeapons.class.common');
  };

  const handleGetWeapon = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmGetWeapon = () => {
    setShowConfirmModal(false);
    if (selectedWeapon) {
      const finalClass = getWeaponClass(totalScore);
      setFinalWeaponClass(finalClass);
      dispatch(addItem({ 
        id: selectedWeapon.name, 
        quantity: 1, 
        metadata: { class: finalClass, score: totalScore, customName: weaponName } 
      }));
      setShowResultModal(true);
      dispatch(setSelectedLegendaryWeapon(null));
      dispatch(updateTotalScore(0));
      localStorage.removeItem('craftingWeapon');
    }
  };

   const renderWeaponInfo = () => {
    if (!selectedWeapon) return null;
    const currentClass = getWeaponClass(totalScore);
    return (
      <div className="weapon-info">
        <h3>{weaponName || selectedWeapon.name}</h3>
        <p>{t('legendWeapons.currentScore', { score: totalScore })}</p>
        <p>{t('legendWeapons.currentClass', { class: currentClass })}</p>
        <p>{t('legendWeapons.legendaryEnhancements', { count: player.items.LegendaryEnhancement || 0 })}</p>
        {isGetWeaponButtonVisible && (
          <button onClick={handleGetWeapon}>{t('legendWeapons.getWeapon')}</button>
        )}
      </div>
    );
  };

  const renderWeaponSelection = () => (
    <div className="weapon-selection">
      {advancedWeaponTemplates.map(template => (
        <div key={template.name} className="weapon-card">
          <img src={template.image} alt={template.name} />
          <h3>{template.name}</h3>
          <p>{template.description}</p>
          <button 
            onClick={() => startCrafting(template.name)}
            disabled={selectedWeapon || player.items[template.name]}
          >
            {selectedWeapon || player.items[template.name] ? t('legendWeapons.unavailable') : t('legendWeapons.craft')}
          </button>
          {selectedTemplateName === template.name && (
            <div className="legend-resource-requirements">
              <p>
                <span className="required-text">Required:</span>
                {t('legendWeapons.notEnoughResources', {
                  templateName: template.name,
                  materials: template.materials.map(m => `- 1 ${m}`).join('\n'),
                  craftingCost: 1000000,
                  inventory: template.materials.map(m => `- ${player.items[m] || 0} ${m}`).join('\n'),
                  playerCoins: player.coins
                })}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const startMiniGame = () => {
    setShowMiniGame(true);
    setInstructions('');
  };

  const getTimeUntilNextGame = () => {
    if (!nextGameTime) return t('legendWeapons.now');
    const diff = nextGameTime.getTime() - new Date().getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return t('legendWeapons.timeUntilNextGame', { hours, minutes });
  };

  return (
    <div className="legend-weapons-lvl4-container" ref={containerRef}>

      <div className="legend-weapons-lvl4">
        <h2>{t('legendWeapons.title')}</h2>
        
        {instructions && (
          <div className="instructions">
            <pre>{instructions}</pre>
          </div>
        )}

        {renderWeaponInfo()}

        {!selectedWeapon ? renderWeaponSelection() : (
          showMiniGame ? (
            <MiniGames 
              onClose={() => {
                setShowMiniGame(false);
                const gameResult = player.lastMiniGameResult;
                if (gameResult) {
                  handleMiniGameComplete(gameResult);
                }
              }} 
            />
          ) : (
            <div className="mini-game-card">
              <h3>{t('legendWeapons.dailyEnhancement')}</h3>
              <p>{t('legendWeapons.enhancementDescription')}</p>
              {canPlayGame() ? (
                <button onClick={startMiniGame}>{t('legendWeapons.startGame')}</button>
              ) : (
                <p>{t('legendWeapons.nextGameAvailable', { time: getTimeUntilNextGame() })}</p>
              )}
            </div>
          )
        )}

        <Modal isOpen={showNameModal} onClose={() => setShowNameModal(false)}>
          <h2>{t('legendWeapons.nameWeapon')}</h2>
          <p>{t('legendWeapons.nameWeaponDescription')}</p>
          <input 
            type="text" 
            value={weaponName} 
            onChange={(e) => setWeaponName(e.target.value)}
            placeholder={t('legendWeapons.enterWeaponName')}
          />
          <button onClick={handleNameWeapon}>{t('legendWeapons.confirmName')}</button>
        </Modal>

        <Modal isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
          <h2>{t('legendWeapons.areYouSure')}</h2>
          <p>{t('legendWeapons.finalizeWarning')}</p>
          <button onClick={handleConfirmGetWeapon}>{t('legendWeapons.yesGetWeapon')}</button>
          <button onClick={() => setShowConfirmModal(false)}>{t('legendWeapons.noContinueEnhancing')}</button>
        </Modal>

        <div className="arrow-crafting">
          <h3>{t('legendWeapons.craftArrows')}</h3>
          <p>{t('legendWeapons.arrowCraftingCost')}</p>
          <button 
            onClick={handleCraftArrows}
            disabled={player.coins < 10 || (player.items.Wood || 0) < 2}
          >
            {t('legendWeapons.craftArrowsButton')}
          </button>
        </div>

        <Modal isOpen={showResultModal} onClose={() => setShowResultModal(false)}>
          <h2>{t('legendWeapons.congratulations')}</h2>
          <p>{t('legendWeapons.weaponFinalized')}</p>
          <h3>{selectedWeapon?.name}</h3>
          <p>{t('legendWeapons.finalClass', { class: finalWeaponClass })}</p>
          <p>{t('legendWeapons.finalScore', { score: totalScore })}</p>
          <p>{t('legendWeapons.weaponAddedToInventory')}</p>
          <button onClick={() => setShowResultModal(false)}>{t('general.close')}</button>
        </Modal>
      </div>
    </div>
  );
};

export default LegendWeaponsLvl4;