// src/utils/advancedWeaponSystem.js

import { create } from 'ipfs-http-client';
import battleAxeImage from '../images/weapons/battle-axe.jpg';
import spearImage from '../images/weapons/spear.jpg';
import warHammerImage from '../images/weapons/war-hammer.png';
import khopeshImage from '../images/weapons/khopesh.jpg';

const ipfs = create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });

class AdvancedNFTWeapon {
    constructor(name, materials, craftingTask, region) {
        this.name = name;
        this.materials = materials;
        this.craftingTask = craftingTask;
        this.region = region;
        this.attributes = {
            damage: 0,
            speed: 0,
            durability: 0,
            specialAbility: ''
        };
        this.image = '';
        this.externalUrl = '';
        this.lastUpgraded = new Date();
        this.legendaryLevel = 0;
    }


    canUpgrade() {
        const now = new Date();
        const timeSinceLastUpgrade = now - this.lastUpgraded;
        return timeSinceLastUpgrade >= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    }

    upgrade() {
        if (this.canUpgrade()) {
            this.legendaryLevel++;
            this.lastUpgraded = new Date();
            this.applyLegendaryBonus();
            return true;
        }
        return false;
    }

    applyLegendaryBonus() {
        const bonus = this.legendaryLevel * 5; // 5% bonus per legendary level
        for (let attr in this.attributes) {
            if (typeof this.attributes[attr] === 'number') {
                this.attributes[attr] += Math.floor(this.attributes[attr] * (bonus / 100));
            }
        }
    }

    async generateMetadata() {
        return {
            name: `${this.name} (Legendary Level ${this.legendaryLevel})`,
            description: `A powerful ${this.name} crafted in the ${this.region}. Legendary Level: ${this.legendaryLevel}`,
            image: this.image,
            attributes: [
                { trait_type: "Damage", value: this.attributes.damage },
                { trait_type: "Speed", value: this.attributes.speed },
                { trait_type: "Durability", value: this.attributes.durability },
                { trait_type: "Special Ability", value: this.attributes.specialAbility },
                { trait_type: "Legendary Level", value: this.legendaryLevel },
                { trait_type: "Region", value: this.region }
            ],
            external_url: this.externalUrl
        };
    }

    async uploadMetadata() {
        const metadata = await this.generateMetadata();
        const result = await ipfs.add(JSON.stringify(metadata));
        return result.path;
    }
}

export const advancedWeaponTemplates = [
    { 
        name: "Battle Axe", 
        materials: ["Iron", "PapyrusScrollsOfPower", "NileCrocodileScales"], 
        craftingTask: "Forge in volcanic heat", 
        region: "Mountain",
        image: battleAxeImage
    },
    { 
        name: "Spear", 
        materials: ["Wood", "DesertSandCrystal", "Fish"], 
        craftingTask: "Balance in sacred pool", 
        region: "River Valley",
        image: spearImage
    },
    { 
        name: "War Hammer", 
        materials: ["Iron", "SphinxsRiddleStone", "Granite"], 
        craftingTask: "Temper in river rapids", 
        region: "Quarry",
        image: warHammerImage
    },
    { 
        name: "Khopesh", 
        materials: ["Silver", "AnubisFang", "Sand"], 
        craftingTask: "Shape in desert winds", 
        region: "Desert",
        image: khopeshImage
    },
];

class MiniGame {
    constructor(name, description) {
        this.name = name;
        this.description = description;
    }

    async play(player) {
        console.log(`Playing ${this.name}: ${this.description}`);
        // Implement game logic in subclasses
        return Math.floor(Math.random() * 100);
    }
}
    

class ElementalForgeGame extends MiniGame {
    constructor() {
        super("Elemental Forge", "Balance the elements to enhance your weapon's power!");
        this.elements = ['Fire', 'Water', 'Earth', 'Air', 'Light', 'Dark'];
    }

    async play(player) {
        await super.play(player);
        const selectedElements = this.getRandomElements(4);
        let balance = Object.fromEntries(selectedElements.map(e => [e, 50]));
        let rounds = Math.floor(Math.random() * 3) + 3; // 3 to 5 rounds

        console.log(`Balance these elements: ${selectedElements.join(', ')}. You have ${rounds} rounds.`);

        for (let i = 0; i < rounds; i++) {
            console.log(`Round ${i + 1}. Current balance:`, balance);

            // Simulating player input
            const increaseElement = selectedElements[Math.floor(Math.random() * selectedElements.length)];
            const decreaseElement = selectedElements.find(e => e !== increaseElement);

            balance[increaseElement] += 10;
            balance[decreaseElement] -= 10;

            console.log(`Increased ${increaseElement}, Decreased ${decreaseElement}`);
        }

        const totalDifference = Object.values(balance).reduce((sum, value) => sum + Math.abs(value - 50), 0);
        const score = Math.max(0, 100 - totalDifference);

        console.log("Final balance:", balance);
        console.log(`Game completed! Score: ${score}`);
        return score;
    }

    getRandomElements(count) {
        return this.elements.sort(() => 0.5 - Math.random()).slice(0, count);
    }
}

class PrecisionStrikeGame extends MiniGame {
    constructor() {
        super("Precision Strike", "Hit the targets to increase weapon damage!");
    }

    async play(player) {
        await super.play(player);
        const targetCount = Math.floor(Math.random() * 3) + 1; // 1 to 3 targets
        const targets = Array.from({length: targetCount}, () => Math.random());

        console.log(`Hit ${targetCount} target${targetCount > 1 ? 's' : ''}! Swipe to position the marker and tap to strike.`);

        let totalAccuracy = 0;
        for (let i = 0; i < targetCount; i++) {
            const target = targets[i];
            // Simulating player input
            const playerStrike = Math.random();
            const accuracy = 1 - Math.abs(target - playerStrike);
            totalAccuracy += accuracy;

            console.log(`Target ${i + 1} was ${target.toFixed(2)}. You hit ${playerStrike.toFixed(2)}.`);
        }

        const score = Math.floor((totalAccuracy / targetCount) * 100);
        console.log(`Game completed! Score: ${score}`);
        return score;
    }
}

class RunicInscriptionGame extends MiniGame {
    constructor() {
        super("Runic Inscription", "Trace the runes to imbue your weapon with ancient power!");
        this.runes = ['ᚠ', 'ᚢ', 'ᚦ', 'ᚨ', 'ᚱ', 'ᚲ', 'ᚷ', 'ᚹ', 'ᚺ', 'ᚾ', 'ᛁ', 'ᛃ', 'ᛈ', 'ᛉ', 'ᛊ', 'ᛏ', 'ᛒ', 'ᛖ', 'ᛗ', 'ᛚ', 'ᛜ', 'ᛞ', 'ᛟ'];
    }

    async play(player) {
        await super.play(player);
        const sequenceLength = Math.floor(Math.random() * 3) + 3; // 3 to 5 runes
        const sequence = Array.from({length: sequenceLength}, () => this.runes[Math.floor(Math.random() * this.runes.length)]);

        console.log("Trace the following runes in order:");
        console.log(sequence.join(' '));

        // Simulating player tracing
        const playerScore = sequence.map(() => Math.random()).reduce((sum, accuracy) => sum + accuracy, 0) / sequence.length;
        const score = Math.floor(playerScore * 100);

        console.log(`Game completed! Score: ${score}`);
        return score;
    }
}

export class GameSelector {
    constructor() {
        this.games = [
            new ElementalForgeGame(),
            new PrecisionStrikeGame(),
            new RunicInscriptionGame()
        ];
    }

    selectRandomGames(count) {
        return this.games.sort(() => 0.5 - Math.random()).slice(0, count);
    }
}

export class AdvancedWeaponCraftingSystem {
    constructor() {
        this.gameSelector = new GameSelector();
    }

    async craftWeapon(player, weaponTemplate) {
        const weapon = new AdvancedNFTWeapon(
            weaponTemplate.name,
            weaponTemplate.materials,
            weaponTemplate.craftingTask,
            weaponTemplate.region
        );

        const selectedGames = this.gameSelector.selectRandomGames(3);
        for (const game of selectedGames) {
            const score = await game.play(player);
            this.applyGameResult(weapon, game.name, score);
        }

        weapon.externalUrl = `https://gamewebsite.com/weapon-details/${Date.now()}`;
        weapon.image = `https://ipfs.io/ipfs/simulated_image_hash_${Date.now()}`;

        const metadataHash = await weapon.uploadMetadata();

        return { weapon, metadataHash };
    }

    applyGameResult(weapon, gameName, score) {
        switch (gameName) {
            case 'Elemental Forge':
                weapon.attributes.durability = score;
                break;
            case 'Precision Strike':
                weapon.attributes.damage = score;
                break;
            case 'Runic Inscription':
                weapon.attributes.speed = score;
                break;
        }
    }

    generateSpecialAbility(quality) {
        const abilities = ['Fire Burst', 'Ice Shard', 'Thunder Strike', 'Venom Coat', 'Wind Slash'];
        return abilities[Math.floor(quality / 20)];
    }
}

// Export an instance of AdvancedWeaponCraftingSystem
export const advancedWeaponCraftingSystem = new AdvancedWeaponCraftingSystem();

export class AuctionHouse {
    constructor() {
        this.listings = [];
    }

    listWeapon(player, weaponIndex, price) {
        if (weaponIndex < 0 || weaponIndex >= player.weapons.length) {
            console.log("Invalid weapon index.");
            return;
        }

        const weapon = player.weapons[weaponIndex];
        const listingFee = price * 0.05; // 5% listing fee

        if (player.inventory.gold < listingFee) {
            console.log("You don't have enough gold to pay the listing fee.");
            return;
        }

        player.inventory.gold -= listingFee;
        this.listings.push({
            seller: player.name,
            weapon: weapon,
            price: price,
            timeRemaining: 24 // 24-hour auction duration
        });

        player.weapons.splice(weaponIndex, 1);
        console.log(`${player.name} listed their ${weapon.name} for ${price} gold.`);
    }

    // Additional auction methods can be implemented here
}

// You can export other necessary classes or functions here if needed