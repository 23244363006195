import React from 'react';

const PCMessage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img 
        src="/images/No_pc.png" 
        alt="Not available for PC. Please play on mobile or Telegram." 
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      <p>This game is not available for PC. Please play on mobile or Telegram.</p>
    </div>
  );
};

export default PCMessage;