import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateCharacter } from '../redux/playerSlice';
import './CharacterCreation.css';

const CharacterCreation = () => {
  const [gender, setGender] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateName = (input) => {
    const englishRegex = /^[a-zA-Z]+$/;
    if (input.length <= 2) {
      setNameError('Name must be more than 2 letters long.');
      return false;
    } else if (!englishRegex.test(input)) {
      setNameError('Name must contain only English letters.');
      return false;
    } else {
      setNameError('');
      return true;
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    validateName(newName);
  };

  const handleCreate = () => {
    if (gender && name && validateName(name)) {
      dispatch(updateCharacter({ gender, name }));
      localStorage.setItem('characterCreated', 'true');
      navigate('/game');
    } else {
      if (!gender) {
        alert('Please select a gender.');
      } else if (!name) {
        alert('Please enter a name.');
      } else {
        alert(nameError);
      }
    }
  };
  

  return (
    <div className="character-creation">
      <h2>Create Your Character</h2>
      <div>
        <button onClick={() => setGender('male')} className={gender === 'male' ? 'selected' : ''}>Male</button>
        <button onClick={() => setGender('female')} className={gender === 'female' ? 'selected' : ''}>Female</button>
      </div>
      <input
        type="text"
        placeholder="Enter character name"
        value={name}
        onChange={handleNameChange}
      />
      {nameError && <p className="error-message">{nameError}</p>}
      <button onClick={handleCreate}>Create Character</button>
    </div>
  );
};

export default CharacterCreation;