import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCoins, completeAchievement } from '../redux/playerSlice';
import { setHasNewAchievements } from '../redux/gameSlice';
import { achievements } from '../data/achievements';
import './Achievements.css';

const Achievements = ({ setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const completedAchievements = useSelector((state) => state.player.completedAchievements) || [];
  const [newlyCompleted, setNewlyCompleted] = useState([]);

  const checkAchievements = useCallback(() => {
    const newCompleted = [];
    achievements.forEach((achievement) => {
      if (!completedAchievements.includes(achievement.id) && achievement.condition(state)) {
        dispatch(completeAchievement(achievement.id));
        dispatch(addCoins(achievement.reward));
        newCompleted.push(achievement);
      }
    });
    if (newCompleted.length > 0) {
      setNewlyCompleted(newCompleted);
    }
  }, [dispatch, completedAchievements, state]);

  useEffect(() => {
    checkAchievements();
  }, [checkAchievements]);

  useEffect(() => {
    if (newlyCompleted.length > 0) {
      const message = newlyCompleted.map(achievement =>
        t('achievements.unlocked', { name: t(`achievements.${achievement.id}.name`), reward: achievement.reward })
      ).join('\n\n');
      setAlertMessage(message);
      dispatch(setHasNewAchievements(true));
      setNewlyCompleted([]);
    }
  }, [newlyCompleted, dispatch, setAlertMessage, t]);

  return (
    <div className="achievements-scroll">
      <h2 className="scroll-title">{t('achievements.title')}</h2>
      <div className="achievements-list">
        {achievements.map((achievement) => (
          <div key={achievement.id} className={`achievement-item ${completedAchievements.includes(achievement.id) ? 'completed' : ''}`}>
            <div className="achievement-icon">
              {completedAchievements.includes(achievement.id) ? '🏆' : '🔒'}
            </div>
            <div className="achievement-details">
              <h3>{t(`achievements.${achievement.id}.name`)}</h3>
              <p>{t(`achievements.${achievement.id}.description`)}</p>
              <p className="achievement-reward">{t('achievements.reward', { amount: achievement.reward })}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;