import React, { useState, useEffect, useRef } from 'react';
import './EnteringTheTomb.css';

const EnteringTheTomb = ({ onComplete, difficulty, gender }) => {
  const [playerPosition, setPlayerPosition] = useState({ x: 0, y: 0 });
  const [exitPosition, setExitPosition] = useState({ x: 8, y: 8 });
  const [traps, setTraps] = useState([]);
  const [treasures, setTreasures] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [visibleCells, setVisibleCells] = useState([]);
  const [collectedGem, setCollectedGem] = useState(null);
  const [controlOrder, setControlOrder] = useState(['up', 'down', 'left', 'right']);
  const [showEarthquake, setShowEarthquake] = useState(false);
  const [snakeBite, setSnakeBite] = useState(null);
  const [cellSize, setCellSize] = useState(40);
  const [earthquakeSound, setEarthquakeSound] = useState(null);
  const gridRef = useRef(null);

  const gridSize = 9;

  useEffect(() => {
    const sound = new Audio('/sounds/Earthquake.mp3');
    sound.loop = true;
    setEarthquakeSound(sound);

    return () => {
      sound.pause();
      sound.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    const trapCount = difficulty === 'Extra Hard' ? 25 : difficulty === 'Hard' ? 20 : 10;
    const treasureCount = difficulty === 'Extra Hard' ? 5 : difficulty === 'Hard' ? 8 : 10;

    setTraps(generateRandomPositions(trapCount));
    setTreasures(generateRandomPositions(treasureCount));
    updateVisibleCells({ x: 0, y: 0 });

    const earthquakeInterval = setInterval(() => {
      if (earthquakeSound) {
        earthquakeSound.play();
        setTimeout(() => {
          earthquakeSound.pause();
          earthquakeSound.currentTime = 0;
        }, 3000);
      }
      setTimeout(() => {
        setShowEarthquake(true);
        setTimeout(() => setShowEarthquake(false), 2000);
        setControlOrder(shuffleArray([...controlOrder]));
      }, 1000);
    }, 5000);

    const handleResize = () => {
      if (gridRef.current) {
        const gridWidth = gridRef.current.offsetWidth;
        const newCellSize = Math.floor(gridWidth / gridSize) - 2;
        setCellSize(Math.max(newCellSize, 20));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(earthquakeInterval);
      window.removeEventListener('resize', handleResize);
    }
  }, [difficulty, earthquakeSound]);

  const generateRandomPositions = (count) => {
    const positions = [];
    while (positions.length < count) {
      const pos = {
        x: Math.floor(Math.random() * gridSize),
        y: Math.floor(Math.random() * gridSize)
      };
      if (!positionEquals(pos, playerPosition) && !positionEquals(pos, exitPosition) && !positions.some(p => positionEquals(p, pos))) {
        positions.push(pos);
      }
    }
    return positions;
  };

  const positionEquals = (pos1, pos2) => pos1.x === pos2.x && pos1.y === pos2.y;

  const updateVisibleCells = (position) => {
    const newVisibleCells = [];
    for (let dy = -1; dy <= 1; dy++) {
      for (let dx = -1; dx <= 1; dx++) {
        const newX = position.x + dx;
        const newY = position.y + dy;
        if (newX >= 0 && newX < gridSize && newY >= 0 && newY < gridSize) {
          newVisibleCells.push({ x: newX, y: newY });
        }
      }
    }
    setVisibleCells(newVisibleCells);
  };

  const movePlayer = (dx, dy) => {
    if (gameOver) return;

    const newPosition = {
      x: Math.max(0, Math.min(gridSize - 1, playerPosition.x + dx)),
      y: Math.max(0, Math.min(gridSize - 1, playerPosition.y + dy))
    };

    setPlayerPosition(newPosition);
    updateVisibleCells(newPosition);

    // Check for traps
    if (traps.some(trap => positionEquals(trap, newPosition))) {
      const lostScore = Math.floor(score * 0.5);
      setScore(score - lostScore);
      setSnakeBite({ x: newPosition.x, y: newPosition.y, value: lostScore });
      setTimeout(() => setSnakeBite(null), 1000);
    }

    // Check for treasures
    const treasureIndex = treasures.findIndex(treasure => positionEquals(treasure, newPosition));
    if (treasureIndex !== -1) {
      setScore(score + 10);
      setCollectedGem({ x: newPosition.x, y: newPosition.y, value: 10 });
      setTimeout(() => setCollectedGem(null), 1000);
      setTreasures(treasures.filter((_, index) => index !== treasureIndex));
    }

    // Check for exit
    if (positionEquals(newPosition, exitPosition)) {
      setGameOver(true);
      onComplete(score + 50);
    }
  };

  const renderGrid = () => {
    const grid = [];
    for (let y = 0; y < gridSize; y++) {
      for (let x = 0; x < gridSize; x++) {
        const key = `${x}-${y}`;
        let cellContent = null;
        let cellClass = 'cell';

        const isVisible = visibleCells.some(cell => positionEquals(cell, { x, y }));
        const isTrap = traps.some(trap => positionEquals(trap, { x, y }));
        const isTreasure = treasures.some(treasure => positionEquals(treasure, { x, y }));

        if (positionEquals({ x, y }, playerPosition)) {
          cellContent = gender === 'female' ? '👩' : '🧑';
        } else if (positionEquals({ x, y }, exitPosition)) {
          cellContent = '🚪';
        } else if (isVisible && isTrap) {
          cellContent = '🐍';
        } else if (isVisible && isTreasure) {
          cellContent = '💎';
        }

        if (isVisible) {
          cellClass += isTrap ? ' danger' : ' safe';
        } else {
          cellClass += ' hidden';
        }

        grid.push(
          <div key={key} className={cellClass} style={{ width: `${cellSize}px`, height: `${cellSize}px` }}>
            {cellContent}
            {collectedGem && positionEquals(collectedGem, { x, y }) && (
              <div className="gem-animation">+{collectedGem.value}</div>
            )}
            {snakeBite && positionEquals(snakeBite, { x, y }) && (
              <div className="snake-bite-animation">-{snakeBite.value}</div>
            )}
          </div>
        );
      }
    }
    return grid;
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const renderControls = () => {
    const controlMap = {
      up: { emoji: '⬆️', move: () => movePlayer(0, -1) },
      down: { emoji: '⬇️', move: () => movePlayer(0, 1) },
      left: { emoji: '⬅️', move: () => movePlayer(-1, 0) },
      right: { emoji: '➡️', move: () => movePlayer(1, 0) }
    };

    return (
      <div className="controls">
        {controlOrder.map(control => (
          <button key={control} onClick={controlMap[control].move}>
            {controlMap[control].emoji}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="entering-the-tomb tomb">
      <h2>Entering the Tomb</h2>
      <div className="grid-container" ref={gridRef}>
        <div className="grid" style={{ gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
          {renderGrid()}
        </div>
      </div>
      {renderControls()}
      <p>Score: {score}</p>
      {showEarthquake && <div className="earthquake">Earthquake!</div>}
      {gameOver && <p>Game Over! Final Score: {score}</p>}
    </div>
  );
};

export default EnteringTheTomb;