import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { spendCoins, spendItem } from '../redux/playerSlice';
import GoodWeaponAnimation from './GoodWeaponAnimation';
import './WeaponLvl2Arrow.css';

const WeaponLvl2Arrow = ({ onStartActivity, onClose, setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { coins, items } = useSelector((state) => state.player);
  const [craftingItem, setCraftingItem] = useState(null);
  const [showGoodWeaponAnimation, setShowGoodWeaponAnimation] = useState(false);

  const craftingRequirements = {
    BowFrame: { coins: 50, Wood: 2 },
    Bow: { coins: 50, Leather: 4, BowFrame: 1 },
    Arrow: { coins: 10, Wood: 2 },
  };

  const startCrafting = (item, duration) => {
    const requirements = craftingRequirements[item];
    const hasRequirements = Object.entries(requirements).every(([resource, amount]) => 
      (resource === 'coins' ? coins : items[resource] || 0) >= amount
    );

    if (hasRequirements) {
      Object.entries(requirements).forEach(([resource, amount]) => {
        if (resource === 'coins') {
          dispatch(spendCoins(amount));
        } else {
          dispatch(spendItem({ id: resource, quantity: amount }));
        }
      });
      setCraftingItem(item);
      onStartActivity(`Craft${item}`, duration);
      if (item === 'Bow') setShowGoodWeaponAnimation(true);
    } else {
      setAlertMessage(t('errors.notEnoughResources'));
    }
  };

  const canCraftBowFrame = (items.BowFrame || 0) === 0 && (items.Bow || 0) === 0;
  const canCompleteBow = (items.BowFrame || 0) === 1 && (items.Bow || 0) === 0;

  const renderRequirements = (item) => {
    return Object.entries(craftingRequirements[item]).map(([resource, amount]) => {
      let translationKey;
      if (resource === 'coins') {
        translationKey = 'weaponCrafting.commonResources.coins';
      } else if (resource === 'Wood') {
        translationKey = 'weaponCrafting.commonResources.wood';
      } else {
        const lowercaseResource = resource.charAt(0).toLowerCase() + resource.slice(1);
        translationKey = `weaponCraftingLvl2.resources.${lowercaseResource}`;
      }
      return `${amount} ${t(translationKey)}`;
    }).join(', ');
  };

  return (
    <div className="wc-container">
      <h2 className="wc-title">{t('weaponCrafting.title')}</h2>
      <div className="wc-resources">
        <p className="wc-resource-item">{t('weaponCrafting.commonResources.coins')}: {coins}</p>
        <p className="wc-resource-item">{t('weaponCrafting.commonResources.wood')}: {items.Wood || 0}</p>
        <p className="wc-resource-item">{t('weaponCraftingLvl2.resources.leather')}: {items.Leather || 0}</p>
        <p className="wc-resource-item">{t('weaponCraftingLvl2.resources.bowFrame')}: {items.BowFrame || 0}</p>
        <p className="wc-resource-item">{t('weaponCraftingLvl2.resources.bow')}: {items.Bow || 0}</p>
        <p className="wc-resource-item">{t('weaponCrafting.commonResources.arrows')}: {items.Arrow || 0}</p>
      </div>
      <div className="wc-crafting-options">
        <div className="wc-crafting-option">
          <p className="wc-requirements">{t('weaponCraftingLvl2.requirementsBowFrame', { requirements: renderRequirements('BowFrame') })}</p>
          <button 
            className="wc-craft-button"
            onClick={() => startCrafting('BowFrame', 2 * 60 * 60* 1000)} 
            disabled={!canCraftBowFrame || craftingItem}
          >
            {t('weaponCraftingLvl2.craftBowFrameButton')}
          </button>
        </div>
        <div className="wc-crafting-option">
          <p className="wc-requirements">{t('weaponCraftingLvl2.requirementsBow', { requirements: renderRequirements('Bow') })}</p>
          <button 
            className="wc-craft-button"
            onClick={() => startCrafting('Bow', 3 * 60 * 60* 1000)} 
            disabled={!canCompleteBow || craftingItem}
          >
            {t('weaponCraftingLvl2.craftBowButton')}
          </button>
        </div>
        <div className="wc-crafting-option">
          <p className="wc-requirements">{t('weaponCraftingLvl2.requirementsArrow', { requirements: renderRequirements('Arrow') })}</p>
          <button 
            className="wc-craft-button"
            onClick={() => startCrafting('Arrow', 30 * 60* 1000)} 
            disabled={craftingItem}
          >
            {t('weaponCraftingLvl2.craftArrowsButton')}
          </button>
        </div>
      </div>
      {craftingItem && (
        <p className="wc-progress-message">{t('weaponCrafting.craftingStatus', { item: t(`weaponCraftingLvl2.craft${craftingItem}`) })}</p>
      )}
      {showGoodWeaponAnimation && (
        <GoodWeaponAnimation
          item="Bow"
          onClose={() => setShowGoodWeaponAnimation(false)}
        />
      )}
    </div>
  );
};

export default WeaponLvl2Arrow;