import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { spendCoins, spendItem, addPower, addItem } from '../redux/playerSlice';
import GoodWeaponAnimation from './GoodWeaponAnimation';
import './WeaponLvl3SmallSword.css';

const WeaponLvl3SmallSword = ({ onStartActivity, onClose, setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { coins, items, power } = useSelector((state) => state.player);
  const [craftingItem, setCraftingItem] = useState(null);
  const [showGoodWeaponAnimation, setShowGoodWeaponAnimation] = useState(false);

  const startCrafting = (item, duration) => {
    const craftingActions = {
      BlacksmithTrust: () => {
        if (coins >= 100) { // Doubled from 50
          dispatch(spendCoins(100)); // Doubled from 50
          return true;
        }
        return false;
      },
      BlacksmithBelongings: () => {
        if ((items.BlacksmithTrust || 0) >= 1 && power >= 180) {
          dispatch(spendCoins(100)); // Doubled from 50
          dispatch(spendItem({ id: 'BlacksmithTrust', quantity: 1 }));
          dispatch(spendItem({ id: 'Blacksmithitems', quantity: 1 }));
          dispatch(addItem({ id: 'BlacksmithBelongings', quantity: 1 }));
          return true;
        }
        return false;
      },
      SwordHilt: () => {
        if ((items.BlacksmithBelongings || 0) >= 1 && (items.Iron || 0) >= 5) {
          dispatch(spendCoins(300)); // Doubled from 150
          dispatch(spendItem({ id: 'BlacksmithBelongings', quantity: 1 }));
          return true;
        }
        return false;
      },
      UnfinishedSmallSword: () => {
        if ((items.SwordHilt || 0) >= 1 && (items.Iron || 0) >= 2 && (items.Silver || 0) >= 3) {
          dispatch(spendCoins(400)); // Doubled from 200
          dispatch(spendItem({ id: 'Iron', quantity: 2 }));
          dispatch(spendItem({ id: 'Silver', quantity: 3 }));
          dispatch(spendItem({ id: 'SwordHilt', quantity: 1 }));
          return true;
        }
        return false;
      },
      SmallSword: () => {
        if ((items.UnfinishedSmallSword || 0) >= 1 && power >= 275) {
          dispatch(spendCoins(600)); // Doubled from 300
          dispatch(spendItem({ id: 'UnfinishedSmallSword', quantity: 1 }));
          dispatch(addItem({ id: 'SmallSword', quantity: 1 }));
          setShowGoodWeaponAnimation(true);
          return true;
        }
        return false;
      },
      Arrow: () => {
        if (coins >= 20 && (items.Wood || 0) >= 2) { // Doubled from 10
          dispatch(spendCoins(20)); // Doubled from 10
          dispatch(spendItem({ id: 'Wood', quantity: 2 }));
          dispatch(addItem({ id: 'Arrow', quantity: 10 }));
          return true;
        }
        return false;
      }
    };

    if (craftingActions[item] && craftingActions[item]()) {
      setCraftingItem(item);
      onStartActivity(`Craft${item}`, duration);
    } else {
      setAlertMessage(t('weaponCrafting.notEnoughResources'));
    }
  };

  const getCurrentCraftingStage = () => {
    if ((items.SmallSword || 0) >= 1) return 'completed';
    if ((items.UnfinishedSmallSword || 0) >= 1) return 'UnfinishedSmallSword';
    if ((items.SwordHilt || 0) >= 1) return 'SwordHilt';
    if ((items.BlacksmithBelongings || 0) >= 1) return 'BlacksmithBelongings';
    if ((items.BlacksmithTrust || 0) >= 1) return 'BlacksmithTrust';
    return 'Initial';
  };

  const renderCraftingButton = () => {
    const currentStage = getCurrentCraftingStage();
    const stageConfig = {
      Initial: {
        story: 'initialStory',
        action: () => startCrafting('BlacksmithTrust', 2 * 60 * 60 * 1000),
        buttonText: 'bribeBlacksmith',
        disabled: craftingItem || coins < 100, // Doubled from 50
        buttonProps: { coins: 100 } // Doubled from 50
      },
      BlacksmithTrust: {
        story: 'blacksmithTrustStory',
        action: () => startCrafting('BlacksmithBelongings', 5 * 60 * 1000),
        buttonText: 'raidBanditCamp',
        disabled: craftingItem || power < 180,
        buttonProps: { power: 180 }
      },
      BlacksmithBelongings: {
        story: 'blacksmithBelongingsStory',
        action: () => startCrafting('SwordHilt', 5 * 60 * 60 * 1000),
        buttonText: 'startCraftingHilt',
        disabled: craftingItem || coins < 300 || (items.Iron || 0) < 5, // Doubled from 150
        buttonProps: { coins: 300, iron: 5 } // Doubled from 150
      },
      SwordHilt: {
        story: 'unfinishedSmallSwordStory',
        action: () => startCrafting('UnfinishedSmallSword', 12 * 60 * 60 * 1000),
        buttonText: 'startForgingBlade',
        disabled: craftingItem || coins < 400 || (items.Iron || 0) < 2 || (items.Silver || 0) < 3, // Doubled from 200
        buttonProps: { coins: 400, iron: 2, silver: 3 } // Doubled from 200
      },
      UnfinishedSmallSword: {
        story: 'smallSwordStory',
        action: () => startCrafting('SmallSword', 22 * 60 * 60 * 1000),
        buttonText: 'startFinalChallenge',
        disabled: craftingItem || coins < 600 || power < 275, // Doubled from 300
        buttonProps: { coins: 600, power: 275 } // Doubled from 300
      },
      completed: {
        story: 'smallSwordCompleted'
      }
    };

    const stage = stageConfig[currentStage];
    if (!stage) return null;

    return (
      <>
        <p className="story-text">{t(`weaponCraftingLvl3.${stage.story}`)}</p>
        {stage.action && (
          <button 
            onClick={stage.action} 
            disabled={stage.disabled}
          >
            {t(`weaponCraftingLvl3.${stage.buttonText}`, stage.buttonProps)}
          </button>
        )}
      </>
    );
  };

  return (
    <div className="weapon-craft-container">
      <h2>{t('weaponCrafting.title')}</h2>
      
      <div className="resources">
        {['coins', 'wood', 'iron', 'silver', 'arrows'].map(resource => (
          <p key={resource}>
            {t(`weaponCrafting.commonResources.${resource}`)}: {resource === 'coins' ? coins : items[resource.charAt(0).toUpperCase() + resource.slice(1)] || 0}
          </p>
        ))}
        <p>{t('weaponCrafting.yourPower')}: {power}</p>
      </div>

      <div className="crafting-section">
        {renderCraftingButton()}
      </div>

      <div className="arrow-crafting">
        <p className="story-text">{t('weaponCraftingLvl3.arrowCrafting')}</p>
        <button 
          onClick={() => startCrafting('Arrow', 25 * 60* 1000)}
          disabled={craftingItem || coins < 10 || (items.Wood || 0) < 2} // Doubled from 10
        >
          {t('weaponCraftingLvl3.craftArrows', { coins: 10, wood: 2 })} // Doubled from 10
        </button>
      </div>

      {craftingItem && (
        <p className="crafting-status">{t('weaponCrafting.craftingStatus', { item: craftingItem })}</p>
      )}

      {showGoodWeaponAnimation && (
        <GoodWeaponAnimation 
          item="SmallSword" 
          onClose={() => setShowGoodWeaponAnimation(false)} 
        />
      )}
    </div>
  );
};

export default WeaponLvl3SmallSword;