import React from 'react';
import './FullScreenMobileModal.css'; // We'll create this file for styling

const FullScreenMobileModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="full-screen-mobile-modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default FullScreenMobileModal;