import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addItem, addCoins, spendItem } from '../redux/playerSlice';
import './MapExploration.css';

// Import images (make sure these exist in your project)
import mountain from '../images/buttons/mountain.jpg';
import secretTombs from '../images/buttons/secret_tombs.jpg';
import riverValley from '../images/buttons/river_valley.jpg';
import quarry from '../images/buttons/quarry.jpg';
import desert from '../images/buttons/desert.jpg';
import royalTournament from '../images/buttons/royal_tournament.jpg';

export const regions = [
  { name: 'Mountain', image: mountain, description: 'A rugged, rocky terrain.', cost: 100, duration: 120, baseRewards: { coins: 50, items: [
    { id: 'Rock', quantity: 1 }, 
    { id: 'Ore', quantity: 1 },
    { id: 'Iron', quantity: 1 },
    { id: 'Wood', quantity: 1 }
  ]  } },
  { name: 'Secret Tombs', image: secretTombs, description: 'Ancient tombs with hidden treasures.', cost: 150, duration: 150, baseRewards: { coins: 100, items: [
    { id: 'Ancient Artifact', quantity: 1 }, 
    { id: 'Treasure Map', quantity: 1 },
    { id: 'Leather', quantity: 1 }
  ]  } },
  { name: 'River Valley', image: riverValley, description: 'A lush valley with flowing rivers.', cost: 120, duration: 180, baseRewards: { coins: 70, items: [
    { id: 'Fish', quantity: 1 }, 
    { id: 'Pearl', quantity: 1 },
    { id: 'FlexibleWood', quantity: 1 }
  ] } },
  { name: 'Quarry', image: quarry, description: 'A place for mining valuable stones.', cost: 80, duration: 300, requiredLevel: 3, baseRewards: { coins: 40, items: [
    { id: 'Stone', quantity: 1 }, 
    { id: 'Gem', quantity: 1 },
    { id: 'Granite', quantity: 1 }
  ]  } },
  { name: 'Desert', image: desert, description: 'A vast and arid desert.', cost: 200, duration: 360, requiredLevel: 3, baseRewards: { coins: 150, items: [
    { id: 'Sand', quantity: 1 }, 
    { id: 'Cactus', quantity: 1 },
    { id: 'Feathers', quantity: 1 }
  ]  } },
  { name: 'Royal Tournament Grounds', image: royalTournament, description: 'Venue for grand tournaments.', cost: 2500, duration: 20, requiredLevel: 5, baseRewards: { coins: 200, items: [{ id: 'Trophy', quantity: 1 }] } }
];

export const rareItems = [
  'PapyrusScrollsOfPower', 'DesertSandCrystal', 'MummysLinen', 'SphinxsRiddleStone', 'AnubisFang', 'RasSunstone', 'NileLotusEssence', 'PharaohsGold', 'NileCrocodileScales', 'ScarabBeetleEssence',
  'FlexibleWood', 'Feathers', 'Steel', 'Granite', 'Bronze', 'Gold', 'LapisLazuli'
];

const MapExploration = ({ onExploreStart, onClose, setAlertMessage }) => {
  const { t } = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState(null);
  const player = useSelector((state) => state.player);
  const dispatch = useDispatch();
  const canHunt = selectedRegion?.name === 'Mountain' && player.items['Bow'] > 0 && (player.items['Arrow'] || 0) >= 5;

  const getRareItemChance = (playerLevel, playerKnowledge) => {
    if (playerLevel > 3 && playerKnowledge > 100) {
      return 0.50; // 50% chance
    } else if (playerLevel > 3 && playerKnowledge > 50) {
      return 0.25; // 25% chance
    } else {
      return 0.10; // 10% chance (base chance)
    }
  };

  const selectRegion = (region) => {
    if (player.level < (region.requiredLevel || 0)) {
      setAlertMessage(t('mapExploration.levelRequirement', { level: region.requiredLevel }));
      return;
    }
    setSelectedRegion(region);
  };

  const startExploration = () => {
    if (!selectedRegion) {
      setAlertMessage(t('mapExploration.selectRegion'));
      return;
    }
  
    if (player.coins < selectedRegion.cost) {
      setAlertMessage(t('mapExploration.notEnoughCoins'));
      return;
    }
  
    dispatch(addCoins(-selectedRegion.cost));
  
    let finalRewards = { ...selectedRegion.baseRewards };
  
    if (selectedRegion.name === 'River Valley' && player.items['BlacksmithTrust'] >= 1) {
      finalRewards.items.push({ id: 'Blacksmithitems', quantity: 1 });
    }
  
    if (selectedRegion.name === 'Mountain' && player.items['Bow'] > 0 && player.items['Arrow'] >= 5) {
      finalRewards.items.push({ id: 'Leather', quantity: 15 });
      dispatch(spendItem({ id: 'Arrow', quantity: 5 }));
    }
  
    const rareItemChance = getRareItemChance(player.level, player.knowledge);
    if (Math.random() <= rareItemChance) {
      const rareItem = rareItems[Math.floor(Math.random() * rareItems.length)];
      finalRewards.items.push({ id: rareItem, quantity: 1 });
      setAlertMessage(t('mapExploration.rareItemFound', { item: rareItem }));
    }
  
    onExploreStart(selectedRegion, finalRewards);
    onClose();
  };

  return (
    <div className="ae-map-exploration">
      <div className="ae-card-container">
        {regions.map((region) => (
          <div
            key={region.name}
            className={`ae-map-card ${selectedRegion?.name === region.name ? 'selected' : ''} ${player.level < (region.requiredLevel || 0) ? 'disabled' : ''}`}
            onClick={() => selectRegion(region)}
            style={{ backgroundImage: `url(${region.image})` }}
          >
            <div className="ae-card-content">
              <h3>{region.name}</h3>
              <p>{region.description}</p>
              <p>{t('mapExploration.travelCost', { cost: region.cost })}</p>
              <p>{t('mapExploration.duration', { duration: region.duration })}</p>
              {region.requiredLevel && player.level < region.requiredLevel && (
                <p className="ae-level-requirement">{t('mapExploration.requiresLevel', { level: region.requiredLevel })}</p>
              )}
            </div>
            {selectedRegion?.name === region.name && <div className="ae-tick-mark">✓</div>}
          </div>
        ))}
      </div>
      {selectedRegion && (
        <div className="ae-selected-region-info">
          <h3>{selectedRegion.name}</h3>
          <p>{t('mapExploration.cost', { cost: selectedRegion.cost })}</p>
          <p>{t('mapExploration.durationMinutes', { duration: selectedRegion.duration })}</p>
          {canHunt && (
            <p className="ae-hunting-available">
              <strong>{t('mapExploration.huntingAvailable')}</strong>
            </p>
          )}
          <button className="ae-explore-button" onClick={startExploration} disabled={player.coins < selectedRegion.cost}>
            {t('mapExploration.startExploring')}
          </button>
        </div>
      )}
    </div>
  );
};

export default MapExploration;