import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCoins, addItem, addSkin } from '../redux/playerSlice';
import { Header, FlexContainer } from './UIComponents';
import { boostService } from '../services/BoostService';
import './Market.css';
import Auction from './Auction';
import { boosts, skins, items, specialItems } from '../data/shopItems';
import noimage from '../images/icons/no-image.png';

const Market = ({ setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const [activeTab, setActiveTab] = useState('boost');
  const [purchaseCounts, setPurchaseCounts] = useState({});
  const [, forceUpdate] = useState();

  const checkBoostsStatus = useCallback(() => {
    boostService.removeExpiredBoosts();
    forceUpdate({});
  }, []);

  useEffect(() => {
    boostService.loadBoosts();
    checkBoostsStatus();
    const interval = setInterval(checkBoostsStatus, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [checkBoostsStatus]);

  useEffect(() => {
    checkBoostsStatus();
  }, [activeTab, checkBoostsStatus]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    checkBoostsStatus();
  };

  const handlePurchase = (item, isSkin = false, isBoost = false, isSpecial = false) => {
    const discountedCost = Math.floor(item.cost * (1 - (player.shopDiscount || 0)));

    if (player.coins < discountedCost) {
      setAlertMessage(t('market.notEnoughCoins'));
      return;
    }

    if (isSpecial && (purchaseCounts[item.id] || 0) >= 2) {
      setAlertMessage(t('market.purchaseLimitReached'));
      return;
    }

    if (isBoost && boostService.isBoostActive(item.id)) {
      setAlertMessage(t('market.boostAlreadyActive'));
      return;
    }

    dispatch(addCoins(-discountedCost));

    if (isSkin) {
      dispatch(addSkin(item.id));
      dispatch(addItem({ id: item.id, quantity: 1 }));
      setAlertMessage(t('market.purchaseSuccessSkin', { skinId: item.id }));
      forceUpdate({}); // Force re-render to update skin display
    } else if (isBoost) {
      boostService.activateBoost(item.id, item.duration);
      setAlertMessage(t('market.boostActivated', { name: t(item.name), duration: item.duration }));
    } else {
      dispatch(addItem({ id: item.id, quantity: 1 }));
      if (isSpecial) {
        setPurchaseCounts(prev => ({ ...prev, [item.id]: (prev[item.id] || 0) + 1 }));
      }
      setAlertMessage(t('market.purchaseSuccess', { name: t(item.name) }));
    }
    checkBoostsStatus();
  };

  const renderItems = (itemList, isSkin = false, isBoost = false, isSpecial = false) => (
    <div className="panel">
      {itemList.map((item) => {
        const discountedCost = Math.floor(item.cost * (1 - (player.shopDiscount || 0)));
        const isBoostActive = isBoost && boostService.isBoostActive(item.id);
        return (
          <div key={item.id} className="card">
            <img 
              src={item.image} 
              onError={(e) => e.target.src = noimage} 
              alt={item.name} 
              className="item-image" 
            />
            <h3>{t(item.name)}</h3>
            <p>{t(item.description)}</p>
            <p>{t('market.cost', { cost: discountedCost })}</p>
            {item.levelRequired && <p>{t('market.levelRequired', { level: item.levelRequired })}</p>}
            {isBoostActive && (
              <p className="boost-active">
                {t('market.activeFor', { hours: Math.ceil(boostService.getTimeRemaining(item.id) / (60 * 60 * 1000)) })}
              </p>
            )}
            {isSpecial && (
              <p>{t('market.scoreBoost', { minBoost: item.minBoost, maxBoost: item.maxBoost })}</p>
            )}
            <button
              onClick={() => handlePurchase(item, isSkin, isBoost, isSpecial)}
              className="buy-button"
              disabled={
                player.level < (item.levelRequired || 0) || 
                player.coins < discountedCost ||
                (isSpecial && (purchaseCounts[item.id] || 0) >= 2) ||
                isBoostActive
              }
            >
              {isBoostActive ? t('market.active') : 
               isSpecial ? t('market.buyWithLimit', { remaining: 2 - (purchaseCounts[item.id] || 0) }) : 
               t('market.buy')}
            </button>
          </div>
        );
      })}
    </div>
  );

  const renderSkins = () => {
    const playerGender = player.gender || 'male';
    const availableSkins = skins[playerGender].filter(skin => !player.items[skin.id]);
    return renderItems(availableSkins, true);
  };

  const tabs = ['boost', 'skins', 'items', 'special', 'auction'];

  return (
    <div className="market-container">
      <Header>{t('market.header')}</Header>
      <FlexContainer>
        {tabs.map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={`market-tab ${activeTab === tab ? 'active' : ''}`}
          >
            {t(`market.tabs.${tab}`)}
          </button>
        ))}
      </FlexContainer>
      {activeTab === 'boost' && renderItems(boosts, false, true)}
      {activeTab === 'skins' && renderSkins()}
      {activeTab === 'items' && renderItems(items)}
      {activeTab === 'special' && renderItems(specialItems, false, false, true)}
      {activeTab === 'auction' && <Auction setAlertMessage={setAlertMessage} />}
    </div>
  );
};

export default Market;