import React from 'react';
import './WalletModal.css';
const WalletModal = ({ onClose }) => {
  return (
    <div className="wallet-modal">
      <h2>Wallet</h2>
      <p>Coming Soon!</p>
      <p>We're working on integrating wallet functionality. Stay tuned for updates!</p>

    </div>
  );
};

export default WalletModal;