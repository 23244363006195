import React from 'react';
import './TimerDisplay.css';

const TimerDisplay = ({ timeRemaining }) => {
  const formatTime = (milliseconds) => {
    if (milliseconds <= 0) return '00:00:00';

    const totalSeconds = Math.max(0, Math.floor(milliseconds / 1000));
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="timer-display">
      {formatTime(timeRemaining)}
    </div>
  );
};

export default TimerDisplay;