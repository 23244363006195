import React, { useState, useEffect } from 'react';
import './WeaponTestingGame.css';

const WeaponTestingGame = ({ difficulty = 'Medium', onComplete }) => {
  const [score, setScore] = useState(0);
  const [targets, setTargets] = useState(Array(12).fill(false));
  const [gameOver, setGameOver] = useState(false);
  const [roundsPlayed, setRoundsPlayed] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);

  const difficultySettings = {
    'Extra Hard': { interval: 600, duration: 500 },
    'Hard': { interval: 800, duration: 800 },
    'Medium': { interval: 1000, duration: 1000 }
  };

  useEffect(() => {
    if (gameStarted && !gameOver) {
      const { interval, duration } = difficultySettings[difficulty] || difficultySettings['Medium'];

      const gameInterval = setInterval(() => {
        if (roundsPlayed < 30) {
          setTargets(prevTargets => {
            const newTargets = Array(12).fill(false);
            const randomSpot = Math.floor(Math.random() * 12);
            newTargets[randomSpot] = true;
            return newTargets;
          });
          setRoundsPlayed(prev => prev + 1);

          setTimeout(() => {
            setTargets(Array(12).fill(false));
          }, duration);
        } else {
          setGameOver(true);
          onComplete(score);
        }
      }, interval);

      return () => {
        clearInterval(gameInterval);
      };
    }
  }, [gameStarted, gameOver, roundsPlayed, difficulty, score, onComplete]);

  const handleTargetClick = (index) => {
    if (targets[index] && !gameOver) {
      setScore(prevScore => prevScore + 1);
      setTargets(prevTargets => {
        const newTargets = [...prevTargets];
        newTargets[index] = false;
        return newTargets;
      });
    }
  };

  const startGame = () => {
    setGameStarted(true);
    setScore(0);
    setRoundsPlayed(0);
    setGameOver(false);
    setTargets(Array(12).fill(false));
  };

  const renderGrid = () => {
    return (
      <div className="grid">
        {targets.map((isTarget, index) => (
          <div
            key={index}
            onClick={() => handleTargetClick(index)}
            className={`target ${isTarget ? 'active' : ''}`}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="weapon-game">
      <div className="game-container">
        <h2 className="title">Weapon Testing: Combat Trial</h2>
        {!gameStarted ? (
          <button onClick={startGame} className="start-button">Start Game</button>
        ) : (
          <>
            <p className="score">Targets Hit: {score} / {roundsPlayed}</p>
            {renderGrid()}
            {gameOver && (
              <div className="game-over">
                <p>Game Over! Final Score: {score} / 30</p>
                <button onClick={startGame} className="restart-button">Play Again</button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WeaponTestingGame;