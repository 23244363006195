import React, { useEffect } from 'react';
import './GoodWeaponAnimation.css';

const GoodWeaponAnimation = ({ item, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000); // Close after 5 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="good-weapon-overlay">
      <div className="good-weapon-modal">
        <div className="good-weapon-content">
          <h2>Good Weapon Crafted!</h2>
          <div className="good-weapon-image">
            <img src={`/images/${item.toLowerCase()}.png`} alt={item} />
          </div>
          <p>You have crafted a good {item}!</p>
          <div className="sparkles"></div>
        </div>
      </div>
    </div>
  );
};

export default GoodWeaponAnimation;