import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en.json';
import esTranslations from './locales/es.json';
import faTranslations from './locales/fa.json';
import frTranslations from './locales/fr.json';
import deTranslations from './locales/de.json';
import trTranslations from './locales/tr.json';
import arTranslations from './locales/ar.json';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      es: { translation: esTranslations },
      fa: { translation: faTranslations },
      fr: { translation: frTranslations }, 
      de: { translation: deTranslations },
      tr: { translation: trTranslations }, 
      ar: { translation: arTranslations },


    },
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;