import { regions } from '../components/MapExploration';

export const achievements = [
  {
    id: 'first_coin',
    name: 'First Steps',
    description: 'Earn your first coin',
    condition: (state) => (state.player?.coins ?? 0) >= 1,
    reward: 10,
  },
  {
    id: 'work_marathon',
    name: 'Workaholic',
    description: 'Work for a total of 24 hours',
    condition: (state) => (state.game?.workHours ?? 0) >= 24,
    reward: 1000,
  },
  {
    id: 'reach_level_5',
    name: 'Rising Pharaoh',
    description: 'Reach level 5',
    condition: (state) => (state.player?.level ?? 0) >= 5,
    reward: 2000,
  },
  {
    id: 'combat_master',
    name: 'Warrior of the Nile',
    description: 'Defeat 100 enemies in combat',
    condition: (state) => (state.game?.defeatedEnemies ?? 0) >= 100,
    reward: 5000,
  },
  {
    id: 'craft_all_weapons',
    name: 'Master Craftsman',
    description: 'Craft one of each weapon type',
    condition: (state) => 
      (state.player?.items?.Bow ?? 0) > 0 &&
      (state.player?.items?.SmallSword ?? 0) > 0 &&
      (state.player?.items?.BattleAxe ?? 0) > 0 &&
      (state.player?.items?.Spear ?? 0) > 0 &&
      (state.player?.items?.WarHammer ?? 0) > 0 &&
      (state.player?.items?.Khopesh ?? 0) > 0,
    reward: 10000,
  },
  {
    id: 'explore_all_regions',
    name: 'Explorer of the Realms',
    description: 'Explore all available regions',
    condition: (state) => Object.keys(state.game?.exploredRegions ?? {}).length === Object.keys(regions).length,
    reward: 15000,
  },
  {
    id: 'resource_hoarder',
    name: 'Resource Baron',
    description: 'Collect 1000 of any single resource',
    condition: (state) => Object.values(state.player?.items ?? {}).some(quantity => quantity >= 1000),
    reward: 20000,
  },
  {
    id: 'arrow_rain',
    name: 'Rain of Arrows',
    description: 'Craft 1000 arrows',
    condition: (state) => (state.player?.items?.Arrow ?? 0) >= 1000,
    reward: 5000,
  },
  {
    id: 'power_overwhelming',
    name: 'Godlike Power',
    description: 'Reach 10,000 power',
    condition: (state) => (state.player?.power ?? 0) >= 10000,
    reward: 50000,
  },
  {
    id: 'knowledge_seeker',
    name: 'Sage of Alexandria',
    description: 'Reach 5000 knowledge',
    condition: (state) => (state.player?.knowledge ?? 0) >= 5000,
    reward: 30000,
  },
  {
    id: 'coin_emperor',
    name: 'Pharaoh\'s Fortune',
    description: 'Accumulate 10,000,000 coins',
    condition: (state) => (state.player?.coins ?? 0) >= 10000000,
    reward: 1000000,
  },
  {
    id: 'legendary_arsenal',
    name: 'Arsenal of Legends',
    description: 'Craft 5 legendary weapons',
    condition: (state) => Object.values(state.player?.items ?? {}).filter(item => item?.metadata?.class === 'Legendary').length >= 5,
    reward: 100000,
  },
  {
    id: 'social_pharaoh',
    name: 'Pharaoh of the People',
    description: 'Complete all social tasks and refer 50 friends',
    condition: (state) => (state.game?.completedSocialTasks ?? 0) >= 10 && (state.player?.referrals ?? 0) >= 50,
    reward: 50000,
  },
  {
    id: 'artifact_collector',
    name: 'Guardian of History',
    description: 'Collect all Egyptian artifacts and use each in crafting',
    condition: (state) => {
      const artifacts = [
        'PapyrusScrollsOfPower', 'DesertSandCrystal', 'MummysLinen',
        'SphinxsRiddleStone', 'AnubisFang', 'RasSunstone',
        'NileLotusEssence', 'PharaohsGold', 'NileCrocodileScales',
        'ScarabBeetleEssence'
      ];
      return artifacts.every(item => 
        (state.player?.items?.[item] ?? 0) > 0 && 
        (state.game?.itemsUsedInCrafting?.[item] ?? 0) > 0
      );
    },
    reward: 200000,
  },
  {
    id: 'boost_master',
    name: 'Master of Enhancements',
    description: 'Use 1000 boosts of any type',
    condition: (state) => (state.game?.usedBoosts ?? 0) >= 1000,
    reward: 50000,
  },
  {
    id: 'time_traveler',
    name: 'Time Pharaoh',
    description: 'Play the game for a total of 30 days',
    condition: (state) => (state.player?.totalPlayTime ?? 0) >= 30 * 24 * 60 * 60,
    reward: 500000,
  },
  {
    id: 'merchant_tycoon',
    name: 'Merchant of the Nile',
    description: 'Earn 1,000,000 coins from selling items to the merchant',
    condition: (state) => (state.game?.totalMerchantSales ?? 0) >= 1000000,
    reward: 200000,
  },
  {
    id: 'mythical_crafter',
    name: 'Crafter of Myths',
    description: 'Craft a Mythical class weapon',
    condition: (state) => Object.values(state.player?.items ?? {}).some(item => item?.metadata?.class === 'Mythical'),
    reward: 1000000,
  },
  {
    id: 'complete_all_achievements',
    name: 'Eternal Legend',
    description: 'Complete all other achievements',
    condition: (state) => (state.player?.completedAchievements?.length ?? 0) >= achievements.length - 1,
    reward: 5000000,
  },
];