import { createSlice } from '@reduxjs/toolkit';

export const socialSlice = createSlice({
  name: 'social',
  initialState: {
    referredFriends: [],
    totalRewardsClaimed: 0,
  },
  reducers: {
    claimRewards: (state, action) => {
      state.totalRewardsClaimed += action.payload;
    },
    setReferredFriends: (state, action) => {
      state.referredFriends = action.payload;
    },
  },
});

export const { claimRewards, setReferredFriends } = socialSlice.actions;
export default socialSlice.reducer;