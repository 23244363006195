import React, { useState, useEffect, useRef } from 'react';
import './SharpeningChallenge.css';

const SharpeningChallenge = ({ game, difficulty, onComplete }) => {
  const canvasRef = useRef(null);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isGrinding, setIsGrinding] = useState(false);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [targetAngle, setTargetAngle] = useState(0);
  const [grindSound, setGrindSound] = useState(null);
  const [gameSize, setGameSize] = useState({ width: 270, height: 400 });
  const startTimeRef = useRef(Date.now());
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [grindstoneImage, setGrindstoneImage] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Set a fixed tolerance of 10 degrees
  const angleTolerance = 20;

  useEffect(() => {
    const loadImages = async () => {
      const bgImg = new Image();
      const gsImg = new Image();

      bgImg.src = '/images/minigames/oldworkshop.jpg';
      gsImg.src = '/images/minigames/grindstone.png';

      await Promise.all([
        new Promise(resolve => bgImg.onload = resolve),
        new Promise(resolve => gsImg.onload = resolve)
      ]);

      setBackgroundImage(bgImg);
      setGrindstoneImage(gsImg);
      setImagesLoaded(true);
    };

    loadImages();
  }, []);

  useEffect(() => {
    const updateGameSize = () => {
      const maxWidth = Math.min(window.innerWidth, 400);
      const width = Math.floor(maxWidth * 0.9);
      const height = Math.min(window.innerHeight * 0.7, 600);
      setGameSize({ width, height });
    };

    window.addEventListener('resize', updateGameSize);
    updateGameSize();

    return () => window.removeEventListener('resize', updateGameSize);
  }, []);

  useEffect(() => {
    const sound = new Audio('/sounds/grind.mp3');
    sound.loop = true;
    setGrindSound(sound);

    return () => {
      sound.pause();
      sound.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    if (!imagesLoaded) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawGame = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw background image
      ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);
      
      const centerX = canvas.width / 2;
      const centerY = canvas.height * 0.75;
      const baseRadius = Math.min(canvas.width, canvas.height) * 0.15;
      const grindstoneRadius = baseRadius * 1.5;
      
      // Draw grindstone image
      const grindstoneSize = grindstoneRadius * 3;
      const grindstoneY = centerY - grindstoneRadius + 5;
      const grindstoneX = centerX - grindstoneRadius - 45;
      ctx.drawImage(grindstoneImage, grindstoneX, grindstoneY, grindstoneSize, grindstoneSize);
      
      // Draw unplayable area (30 to 160 degrees)
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, grindstoneRadius, (20 * Math.PI) / 180, (160 * Math.PI) / 180);
      ctx.lineTo(centerX, centerY);
      ctx.closePath();
      ctx.fillStyle = 'rgba(255, 0, 0, 0.0)';
      ctx.fill();
      
      // Draw blade
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(currentAngle * Math.PI / 180);
      ctx.fillStyle = 'rgba(204, 204, 204, 0.0)';
      const bladeLength = grindstoneRadius * 2;
      ctx.fillRect(-bladeLength / 2, -1, bladeLength, 2);
      ctx.restore();
      
      // Draw target angle indicator
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(
        centerX + Math.cos(targetAngle * Math.PI / 180) * (grindstoneRadius + 40),
        centerY + Math.sin(targetAngle * Math.PI / 180) * (grindstoneRadius + 40)
      );
      ctx.strokeStyle = 'rgba(255, 255, 0, 0.0)';
      ctx.stroke();
      
      // Draw tolerance range
      ctx.beginPath();
      ctx.arc(centerX, centerY, grindstoneRadius + 10, 
        (targetAngle - angleTolerance) * Math.PI / 180, 
        (targetAngle + angleTolerance) * Math.PI / 180);
      ctx.strokeStyle = 'rgba(0, 255, 0, 0.0)';
      ctx.lineWidth = 5;  
      ctx.stroke();
      
      // Draw UI
      ctx.fillStyle = 'rgba(210, 180, 140, 0.94)';
      ctx.fillRect(10, 10, canvas.width - 20, 60);
      ctx.fillStyle = 'rgba(74, 48, 0, 0.95)';
      ctx.font = '16px Arial';
      ctx.fillText(`Score: ${score}`, 20, 30);
      ctx.fillText(`Time: ${timeLeft}`, canvas.width - 80, 30);
    };

    const gameLoop = setInterval(drawGame, 16);

    return () => clearInterval(gameLoop);
  }, [currentAngle, targetAngle, score, timeLeft, gameSize, angleTolerance, imagesLoaded, backgroundImage, grindstoneImage]);

  useEffect(() => {
    const timer = setInterval(() => {
      const elapsedSeconds = Math.floor((Date.now() - startTimeRef.current) / 1000);
      const newTimeLeft = Math.max(60 - elapsedSeconds, 0);
      setTimeLeft(newTimeLeft);
      
      if (newTimeLeft === 0) {
        clearInterval(timer);
        console.log('Game Over, Final Score:', score);
        onComplete(score);
      }
    }, 100);

    return () => clearInterval(timer);
  }, [score, onComplete]);

  useEffect(() => {
    const changeTargetAngle = () => {
      let newAngle;
      do {
        newAngle = Math.floor(Math.random() * 360);
      } while (newAngle >= 30 && newAngle <= 160);
      setTargetAngle(newAngle);
    };

    changeTargetAngle();
    const targetAngleInterval = setInterval(changeTargetAngle, 5000);

    return () => clearInterval(targetAngleInterval);
  }, []);

  useEffect(() => {
    let scoringInterval;
    if (isGrinding) {
      scoringInterval = setInterval(() => {
        if (isCorrectAngle()) {
          setScore(prevScore => {
            console.log('Incrementing score:', prevScore + 1);
            return prevScore + 1;
          });
          if (grindSound && grindSound.paused) {
            grindSound.play().catch(e => console.error('Error playing sound:', e));
          }
        } else if (grindSound && !grindSound.paused) {
          grindSound.pause();
          grindSound.currentTime = 0;
        }
      }, 100);
    } else if (grindSound && !grindSound.paused) {
      grindSound.pause();
      grindSound.currentTime = 0;
    }

    return () => {
      if (scoringInterval) clearInterval(scoringInterval);
      if (grindSound && !grindSound.paused) {
        grindSound.pause();
        grindSound.currentTime = 0;
      }
    };
  }, [isGrinding, currentAngle, targetAngle, grindSound]);

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();

    const disableScroll = () => {
      document.body.classList.add('no-scroll');
      document.addEventListener('touchmove', preventDefault, { passive: false });
    };

    const enableScroll = () => {
      document.body.classList.remove('no-scroll');
      document.removeEventListener('touchmove', preventDefault);
    };

    disableScroll();

    return () => {
      enableScroll();
    };
  }, []);

  const isCorrectAngle = () => {
    const angleDifference = Math.abs(currentAngle - targetAngle);
    const isCorrect = (angleDifference <= angleTolerance || angleDifference >= (360 - angleTolerance)) &&
                      (currentAngle < 30 || currentAngle > 160);
    console.log('Angle check:', { currentAngle, targetAngle, angleDifference, isCorrect, angleTolerance });
    return isCorrect;
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsGrinding(true);
    handleTouchMove(e);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (e.touches.length > 0) {
      const touch = e.touches[0];
      const rect = canvasRef.current.getBoundingClientRect();
      const centerX = rect.width / 2;
      const centerY = rect.height * 0.75;
      const x = touch.clientX - rect.left - centerX;
      const y = touch.clientY - rect.top - centerY;
      const angle = (Math.atan2(y, x) * 180 / Math.PI + 360) % 360;
      setCurrentAngle(angle);
      console.log('Touch move:', { x, y, angle });
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    setIsGrinding(false);
  };

  if (!imagesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sharpening-challenge-container">
      <h2 className="game-title">{game.name}</h2>
      <canvas
        ref={canvasRef}
        width={gameSize.width}
        height={gameSize.height}
        className="game-canvas"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />

 
    </div>
  );
};

export default SharpeningChallenge;