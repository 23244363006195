// in gameSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isWorking: false,
  isTraining: false,
  isCombatTraining: false,
  workCount: 0,
  trainCount: 0,
  alertMessage: null,
  combatTrainCount: 0,
  hasNewAchievements: false,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setWorking: (state, action) => {
      state.isWorking = action.payload;
      if (action.payload) {
        state.workCount += 1;
      }
    },
    setHasNewAchievements: (state, action) => {
      state.hasNewAchievements = action.payload;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload;
    },
    setTraining: (state, action) => {
      state.isTraining = action.payload;
      if (action.payload) {
        state.trainCount += 1;
      }
    },
    setCombatTraining: (state, action) => {
      state.isCombatTraining = action.payload;
      if (action.payload) {
        state.combatTrainCount += 1;
      }
    },
    resetGameState: () => initialState,
  
  },
});

export const {resetGameState, completeWork, completeTraining,setAlertMessage, completeCombatTraining ,setHasNewAchievements  } = gameSlice.actions;
export default gameSlice.reducer;