import React, { createContext, useContext, useEffect, useState } from 'react';

const TelegramWebAppContext = createContext(null);

export const TelegramWebAppProvider = ({ children }) => {
  const [webApp, setWebApp] = useState(null);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      setWebApp(window.Telegram.WebApp);
    }
  }, []);

  return (
    <TelegramWebAppContext.Provider value={webApp}>
      {children}
    </TelegramWebAppContext.Provider>
  );
};

export const useTelegramWebApp = () => useContext(TelegramWebAppContext);