import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import './HiltCraftingPuzzle.css';

// Import gem images
import redGem from '../../images/minigames/red_gem.png';
import blueGem from '../../images/minigames/blue_gem.png';
import greenGem from '../../images/minigames/green_gem.png';
import yellowGem from '../../images/minigames/yellow_gem.png';
import purpleGem from '../../images/minigames/purple_gem.png';
import orangeGem from '../../images/minigames/orange_gem.png';
import whiteGem from '../../images/minigames/white_gem.png';

const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d2b48c;
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 0 0 auto; // This will push the game to the right
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; // This will prevent scrolling
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  margin-bottom: 20px;
`;

const Cell = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #8b4513;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f5deb3;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  overflow: visible;
  cursor: pointer;

  &:hover {
    background-color: #deb887;
    transform: translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const GemTray = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  margin-bottom: 20px;
`;

const GemHolder = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: all 0.3s ease;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.1) translateY(-4px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    animation: pulse 0.3s infinite;
  }

  &.dragging {
    opacity: 0.5;
    transform: scale(0.9);
  }
`;

const GemImage = styled.img`
  width: 90%;
  height: 90%;
  object-fit: contain;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
`;

const GameInfo = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  color: #8b4513;
`;

const Button = styled.button`
  background-color: #8b4513;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(0);

  &:hover {
    background-color: #a0522d;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const FeedbackDot = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: ${props => `0 0 10px ${props.color}, 0 0 20px ${props.color}`};
  background-color: ${props => props.color};
  transition: all 0.3s ease;
  animation: pop 0.3s ease-out, glow 2s infinite;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;

const gemImages = {
  red: redGem,
  blue: blueGem,
  green: greenGem,
  yellow: yellowGem,
  purple: purpleGem,
  orange: orangeGem,
  white: whiteGem,
};

const getGemColors = (difficulty) => {
  switch (difficulty) {
    case 'Extra Hard':
      return ['red', 'blue', 'green', 'yellow', 'purple', 'orange', 'white'];
    case 'Hard':
      return ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];
    case 'Medium':
    default:
      return ['red', 'blue', 'green', 'yellow', 'purple'];
  }
};

const getGameTime = (difficulty) => {
  switch (difficulty) {
    case 'Extra Hard':
      return 45;
    case 'Hard':
      return 60;
    case 'Medium':
    default:
      return 90;
  }
};

const HiltCraftingPuzzle = ({ game, difficulty, onComplete }) => {
  const [grid, setGrid] = useState(Array(5).fill(null).map(() => Array(5).fill(null)));
  const [correctSequence, setCorrectSequence] = useState([]);
  const [currentRow, setCurrentRow] = useState(0);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(getGameTime(difficulty));
  const [gameState, setGameState] = useState('playing');
  const [selectedGem, setSelectedGem] = useState(null);
  const [feedback, setFeedback] = useState(Array(5).fill(null).map(() => Array(5).fill(null)));

  const gemColors = getGemColors(difficulty);

  useEffect(() => {
    setCorrectSequence(gemColors.slice(0, 5).sort(() => Math.random() - 0.5));
  }, [difficulty]);

  useEffect(() => {
    if (gameState === 'playing' && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      endGame();
    }
  }, [timeLeft, gameState]);

  const endGame = useCallback(() => {
    setGameState('gameover');
    onComplete(score);
  }, [score, onComplete]);

  const handleGemSelect = (color) => {
    if (gameState === 'playing') {
      setSelectedGem(color);
    }
  };

  const handleCellClick = (rowIndex, colIndex) => {
    if (gameState === 'playing' && rowIndex === currentRow && selectedGem) {
      const newGrid = [...grid];
      newGrid[rowIndex][colIndex] = selectedGem;
      setGrid(newGrid);
      setSelectedGem(null);

      if (newGrid[rowIndex].every(cell => cell !== null)) {
        checkGuess(newGrid[rowIndex]);
      }
    }
  };

  const checkGuess = (guess) => {
    let correctPositions = 0;
    const newFeedback = [...feedback];
    const usedCorrect = new Array(5).fill(false);
    const usedGuess = new Array(5).fill(false);

    // Check for correct positions
    for (let i = 0; i < 5; i++) {
      if (guess[i] === correctSequence[i]) {
        newFeedback[currentRow][i] = 'green';
        correctPositions++;
        usedCorrect[i] = true;
        usedGuess[i] = true;
        setScore(prevScore => prevScore + 10);
      }
    }

    // Check for correct colors in wrong positions
    for (let i = 0; i < 5; i++) {
      if (!usedGuess[i]) {
        let found = false;
        for (let j = 0; j < 5; j++) {
          if (!usedCorrect[j] && guess[i] === correctSequence[j]) {
            newFeedback[currentRow][i] = 'yellow';
            usedCorrect[j] = true;
            found = true;
            setScore(prevScore => prevScore + 5);
            break;
          }
        }
        if (!found) {
          newFeedback[currentRow][i] = 'red';
        }
      }
    }

    setFeedback(newFeedback);
    setCurrentRow(prevRow => prevRow + 1);

    if (correctPositions === 5 || currentRow === 4) {
      endGame();
    }
  };

  const getFeedbackColor = (feedbackType) => {
    switch (feedbackType) {
      case 'green': return '#4caf50';
      case 'yellow': return '#ffc107';
      case 'red': return '#f44336';
      default: return 'transparent';
    }
  };

  const handleFeedbackClick = (rowIndex, colIndex) => {
    // You can add more interactivity here, like showing a tooltip or additional information
    console.log(`Clicked feedback at row ${rowIndex}, column ${colIndex}`);
  };

  return (
    <GameContainer className="hilt-crafting-puzzle">
      <h2>{game.name}</h2>
      <GameInfo>
        Score: {(score / 100).toFixed(2)} | Time: {timeLeft} | Row: {currentRow + 1}/5
      </GameInfo>
      <GameGrid>
        {grid.map((row, rowIndex) =>
          row.map((cell, colIndex) => (
            <Cell key={`${rowIndex}-${colIndex}`} onClick={() => handleCellClick(rowIndex, colIndex)}>
              {cell && <GemImage src={gemImages[cell]} alt={cell} />}
              {feedback[rowIndex][colIndex] && (
                <FeedbackDot
                  color={getFeedbackColor(feedback[rowIndex][colIndex])}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFeedbackClick(rowIndex, colIndex);
                  }}
                />
              )}
            </Cell>
          ))
        )}
      </GameGrid>
      <GemTray>
        {gemColors.map(color => (
          <GemHolder key={color} onClick={() => handleGemSelect(color)}>
            <GemImage
              src={gemImages[color]}
              alt={color}
              className={selectedGem === color ? 'gem-selected' : ''}
            />
          </GemHolder>
        ))}
      </GemTray>
      {gameState === 'gameover' && (
        <div>
          <h2>Game Over!</h2>
          <p>Final Score: {score}</p>
          <p>Correct Sequence: {correctSequence.join(', ')}</p>
          <Button onClick={() => window.location.reload()}>Play Again</Button>
        </div>
      )}
    </GameContainer>
  );
};

export default HiltCraftingPuzzle;