import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSkill } from '../redux/playerSlice';
import './SkillSelection.css';

const SkillSelection = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);

  const skillPaths = {
    2: [
      { name: t('skills.bargainHunter.name'), privilege: t('skills.bargainHunter.privilege'), category: t('skills.categories.Shop') },
      { name: t('skills.efficientExtractor.name'), privilege: t('skills.efficientExtractor.privilege'), category: t('skills.categories.Coins') },
      { name: t('skills.networkInitiator.name'), privilege: t('skills.networkInitiator.privilege'), category: t('skills.categories.Referral') },
    ],
    3: [
      { name: t('skills.marketSavant.name'), privilege: t('skills.marketSavant.privilege'), category: t('skills.categories.Shop') },
      { name: t('skills.resourceSpecialist.name'), privilege: t('skills.resourceSpecialist.privilege'), category: t('skills.categories.Coins') },
      { name: t('skills.communityOrganizer.name'), privilege: t('skills.communityOrganizer.privilege'), category: t('skills.categories.Referral') },
    ],
    4: [
      { name: t('skills.economicMastermind.name'), privilege: t('skills.economicMastermind.privilege'), category: t('skills.categories.Shop') },
      { name: t('skills.extractionVirtuoso.name'), privilege: t('skills.extractionVirtuoso.privilege'), category: t('skills.categories.Coins') },
      { name: t('skills.recruitmentTycoon.name'), privilege: t('skills.recruitmentTycoon.privilege'), category: t('skills.categories.Referral') },
    ],
  };

  const availableSkills = skillPaths[player.level] || [];

  const handleSelectSkill = (skill) => {
    dispatch(selectSkill(skill));
    onClose();
  };

  return (
    <div className="skill-selection">
      <h2>{t('skills.chooseNewSkill')}</h2>
      <div className="skill-options">
        {availableSkills.map((skill) => (
          <div key={skill.name} className="skill-card" onClick={() => handleSelectSkill(skill)}>
            <h3>{skill.name}</h3>
            <p>{skill.privilege}</p>
            <span className="skill-category">{skill.category}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillSelection;