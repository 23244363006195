// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import playerReducer, { resetGame as resetPlayerState } from './playerSlice';
import gameReducer from './gameSlice';
import socialReducer from './socialSlice';
import i18n from '../i18n';

const rootReducer = (state, action) => {
  if (action.type === resetPlayerState.type) {
    // Reset the entire state when the resetGame action is dispatched
    // but keep the language setting
    const language = state.player.language;
    state = undefined;
    return {
      player: playerReducer(undefined, action),
      game: gameReducer(undefined, action),
      social: socialReducer(undefined, action),
      language: language
    };
  }
  return {
    player: playerReducer(state?.player, action),
    game: gameReducer(state?.game, action),
    social: socialReducer(state?.social, action),
    language: state?.language
  };
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('gameState');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    
    // Set the language from the saved state
    if (state.language) {
      i18n.changeLanguage(state.language);
    }
    
    return state;
  } catch (err) {
    console.error('Error loading state:', err);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const stateToSave = {
      ...state,
      language: i18n.language // Save the current language
    };
    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem('gameState', serializedState);
  } catch (err) {
    console.error('Error saving state:', err);
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
});

store.subscribe(() => {
  saveState(store.getState());
});

export { store };
export default store;