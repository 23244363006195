import Wood from '../images/shop/items/Wood.png';
import Iron from '../images/shop/items/Iron.png';
import Silver from '../images/shop/items/Silver.png';
import PapyrusScrollsOfPower from '../images/shop/items/PapyrusScrollsofPower.png';
import DesertSandCrystal from '../images/shop/items/DesertSandCrystal.png';
import MummysLinen from '../images/shop/items/MummysLinen.png';
import SphinxsRiddleStone from '../images/shop/items/SphinxsRiddleStone.png';
import AnubisFang from '../images/shop/items/AnubisFang.jpg';
import RasSunstone from '../images/shop/items/RasSunstone.png';
import WorkSpeedBooster from '../images/shop/items/WorkSpeedBooster.jpg';
import CombatTrainingBooster from '../images/shop/items/CombatTrainingBooster.jpg';
import ResearchEffectivenessBooster from '../images/shop/items/ResearchEffectivenessBooster.jpg';
import NileLotusEssence from '../images/shop/items/NileLotusEssence.jpg';
import PharaohsGold from '../images/shop/items/PharaohsGold.png';
import NileCrocodileScales from '../images/shop/items/NileCrocodileScales.jpg';
import ScarabBeetleEssence from '../images/shop/items/ScarabBeetleEssence.jpg';
import Leather from '../images/shop/items/Leather.png';
import Maleskins from '../images/shop/items/Maleskins.jpg';
import Femaleskins from '../images/shop/items/Femaleskins.jpg';

export const boosts = [
  {
    id: 'Work Speed Booster',
    name: 'boosts.workSpeedBooster.name',
    cost: 1000,
    levelRequired: 2,
    duration: 24, // Changed to 24 hours
    image: WorkSpeedBooster,
    description: 'boosts.workSpeedBooster.description',
  },
  {
    id: 'Research Effectiveness Booster',
    name: 'boosts.researchEffectivenessBooster.name',
    cost: 2500,
    levelRequired: 2,
    duration: 24, // Changed to 24 hours
    image: ResearchEffectivenessBooster,
    description: 'boosts.researchEffectivenessBooster.description',
  },
  {
    id: 'Combat Training Booster',
    name: 'boosts.combatTrainingBooster.name',
    cost: 3000,
    levelRequired: 2,
    duration: 24, // Changed to 24 hours
    image: CombatTrainingBooster,
    description: 'boosts.combatTrainingBooster.description',
  },
];

export const skins = {
  male: [
    {
      id: 'PharaohWarrior',
      name: 'skins.male.pharaohWarrior.name',
      cost: 3000,
      levelRequired: 3,
      image: Maleskins,
      description: 'skins.male.pharaohWarrior.description',
    },
    {
      id: 'MummyGuardian',
      name: 'skins.male.mummyGuardian.name',
      cost: 3000,
      levelRequired: 3,
      image: Maleskins,
      description: 'skins.male.mummyGuardian.description',
    },
    {
      id: 'AnubisFighter',
      name: 'skins.male.anubisFighter.name',
      cost: 3500,
      levelRequired: 3,
      image: Maleskins,
      description: 'skins.male.anubisFighter.description',
    },
    {
      id: 'RasChosen',
      name: 'skins.male.rasChosen.name',
      cost: 4000,
      levelRequired: 4,
      image: Maleskins,
      description: 'skins.male.rasChosen.description',
    },
    {
      id: 'SphinxSentinel',
      name: 'skins.male.sphinxSentinel.name',
      cost: 4000,
      levelRequired: 4,
      image: Maleskins,
      description: 'skins.male.sphinxSentinel.description',
    },
    {
      id: 'OsirisReborn',
      name: 'skins.male.osirisReborn.name',
      cost: 4500,
      levelRequired: 4,
      image: Maleskins,
      description: 'skins.male.osirisReborn.description',
    },
    {
      id: 'ScarabKnight',
      name: 'skins.male.scarabKnight.name',
      cost: 3500,
      levelRequired: 3,
      image: Maleskins,
      description: 'skins.male.scarabKnight.description',
    },
    {
      id: 'SetsEnforcer',
      name: 'skins.male.setsEnforcer.name',
      cost: 4500,
      levelRequired: 4,
      image: Maleskins,
      description: 'skins.male.setsEnforcer.description',
    },
  ],
  female: [
    {
      id: 'DesertNomadlvl3',
      name: 'skins.female.desertNomadlvl3.name',
      cost: 3000,
      levelRequired: 3,
      image: Femaleskins,
      description: 'skins.female.desertNomadlvl3.description',
    },
    {
      id: 'DesertNomadlvl4',
      name: 'skins.female.desertNomadlvl4.name',
      cost: 4000,
      levelRequired: 4,
      image: Femaleskins,
      description: 'skins.female.desertNomadlvl4.description',
    },
    {
      id: 'EmperorWarriorlvl3',
      name: 'skins.female.emperorWarriorlvl3.name',
      cost: 3500,
      levelRequired: 3,
      image: Femaleskins,
      description: 'skins.female.emperorWarriorlvl3.description',
    },
    {
      id: 'EmperorWarriorlvl4',
      name: 'skins.female.emperorWarriorlvl4.name',
      cost: 4500,
      levelRequired: 4,
      image: Femaleskins,
      description: 'skins.female.emperorWarriorlvl4.description',
    },
    {
      id: 'Jade',
      name: 'skins.female.jade.name',
      cost: 5000,
      levelRequired: 4,
      image: Femaleskins,
      description: 'skins.female.jade.description',
    },
    {
      id: 'PharaohsGuardlvl4',
      name: 'skins.female.pharaohsGuardlvl4.name',
      cost: 4500,
      levelRequired: 4,
      image: Femaleskins,
      description: 'skins.female.pharaohsGuardlvl4.description',
    },
    {
      id: 'PracticalWarriorlvl3',
      name: 'skins.female.practicalWarriorlvl3.name',
      cost: 3000,
      levelRequired: 3,
      image: Femaleskins,
      description: 'skins.female.practicalWarriorlvl3.description',
    },
    {
      id: 'PracticalWarriorlvl4',
      name: 'skins.female.practicalWarriorlvl4.name',
      cost: 4000,
      levelRequired: 4,
      image: Femaleskins,
      description: 'skins.female.practicalWarriorlvl4.description',
    },
  ],
};

export const items = [
  {
    id: 'Wood',
    name: 'items.wood.name',
    cost: 200,
    image: Wood,
    description: 'items.wood.description',
  },
  {
    id: 'Leather',
    name: 'items.leather.name',
    cost: 300,
    image: Leather,
    description: 'items.leather.description',
  },
  {
    id: 'Iron',
    name: 'items.iron.name',
    cost: 500,
    image: Iron,
    description: 'items.iron.description',
  },
  {
    id: 'Silver',
    name: 'items.silver.name',
    cost: 1000,
    image: Silver,
    description: 'items.silver.description',
  },
];

export const specialItems = [
  {
    id: 'Papyrus Scrolls of Power',
    name: 'specialItems.papyrusScrollsOfPower.name',
    cost: 4000,
    image: PapyrusScrollsOfPower,
    description: 'specialItems.papyrusScrollsOfPower.description',
  },
  {
    id: 'Desert Sand Crystal',
    name: 'specialItems.desertSandCrystal.name',
    cost: 3500,
    image: DesertSandCrystal,
    description: 'specialItems.desertSandCrystal.description',
  },
  {
    id: 'Mummy\'s Linen',
    name: 'specialItems.mummysLinen.name',
    cost: 2000,
    image: MummysLinen,
    description: 'specialItems.mummysLinen.description',
  },
  {
    id: 'Sphinx\'s Riddle Stone',
    name: 'specialItems.sphinxsRiddleStone.name',
    cost: 4500,
    image: SphinxsRiddleStone,
    description: 'specialItems.sphinxsRiddleStone.description',
  },
  {
    id: 'Anubis Fang',
    name: 'specialItems.anubisFang.name',
    cost: 5000,
    image: AnubisFang,
    description: 'specialItems.anubisFang.description',
  },
  {
    id: 'Ra\'s Sunstone',
    name: 'specialItems.rasSunstone.name',
    cost: 6000,
    image: RasSunstone,
    description: 'specialItems.rasSunstone.description',
  },
  {
    id: 'Nile Lotus Essence',
    name: 'specialItems.nileLotusEssence.name',
    cost: 2500,
    image: NileLotusEssence,
    description: 'specialItems.nileLotusEssence.description',
  },
  {
    id: 'Pharaoh\'s Gold',
    name: 'specialItems.pharaohsGold.name',
    cost: 7000,
    image: PharaohsGold,
    description: 'specialItems.pharaohsGold.description',
  },
  {
    id: 'Nile Crocodile Scales',
    name: 'specialItems.nileCrocodileScales.name',
    cost: 3000,
    image: NileCrocodileScales,
    description: 'specialItems.nileCrocodileScales.description',
  },
  {
    id: 'Scarab Beetle Essence',
    name: 'specialItems.scarabBeetleEssence.name',
    cost: 3500,
    image: ScarabBeetleEssence,
    description: 'specialItems.scarabBeetleEssence.description',
  },
];