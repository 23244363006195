import React, { useState, useEffect } from 'react';
import './OreMining.css';

const OreMining = ({ difficulty, onComplete }) => {
  const [rocks, setRocks] = useState([]);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(getGameTime(difficulty));
  const [gameOver, setGameOver] = useState(false);
  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    createRocks();
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setGameOver(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (gameOver) {
      const timeout = setTimeout(() => onComplete(score), 2000);
      return () => clearTimeout(timeout);
    }
  }, [gameOver, score, onComplete]);

  function getGameTime(difficulty) {
    switch (difficulty) {
      case 'Extra Hard': return 45;
      case 'Hard': return 60;
      case 'Medium': default: return 90;
    }
  }

  function createRocks() {
    const difficulties = getDifficulties(difficulty);
    const specialOreIndices = getRandomIndices(2, 9);
    const newRocks = Array(3).fill().map(() => 
      Array(3).fill().map((_, colIndex) => ({
        health: difficulties[Math.floor(Math.random() * difficulties.length)],
        specialOre: specialOreIndices.includes(colIndex),
        broken: false
      }))
    );
    setRocks(newRocks);
  }

  function getDifficulties(difficulty) {
    switch (difficulty) {
      case 'Extra Hard': return [45, 45, 80, 80];
      case 'Hard': return [20, 45, 45, 80];
      case 'Medium': default: return [20, 20, 45, 45];
    }
  }

  function getRandomIndices(count, max) {
    const indices = [];
    while (indices.length < count) {
      const index = Math.floor(Math.random() * max);
      if (!indices.includes(index)) {
        indices.push(index);
      }
    }
    return indices;
  }

  function handleRockClick(rowIndex, colIndex, event) {
    if (gameOver || rocks[rowIndex][colIndex].broken) return;
  
    const rock = event.currentTarget;
    const rect = rock.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
  
    // Create dust particles
    createDustParticles(rock, x, y);

    setRocks(prevRocks => {
      const newRocks = prevRocks.map(row => [...row]);
      newRocks[rowIndex][colIndex] = {
        ...newRocks[rowIndex][colIndex],
        health: newRocks[rowIndex][colIndex].health - 1
      };

      if (newRocks[rowIndex][colIndex].health <= 0) {
        newRocks[rowIndex][colIndex].broken = true;
        const scoreGain = newRocks[rowIndex][colIndex].specialOre ? 10 : 3;
        setScore(prevScore => prevScore + scoreGain);
        showScoreAnimation(event.clientX, event.clientY, scoreGain);
      }

      return newRocks;
    });
  }

  function createDustParticles(rock, x, y) {
    const numParticles = 5; // Number of dust particles per tap
  
    for (let i = 0; i < numParticles; i++) {
      const particle = document.createElement('div');
      particle.className = 'dust-particle';
      
      // Set initial position
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;
  
      // Set random direction for each particle
      const angle = Math.random() * Math.PI * 2;
      const distance = 20 + Math.random() * 30; // Random distance between 20 and 50 pixels
      const tx = Math.cos(angle) * distance;
      const ty = Math.sin(angle) * distance;
  
      particle.style.setProperty('--tx', `${tx}px`);
      particle.style.setProperty('--ty', `${ty}px`);
  
      // Apply the animation
      particle.style.animation = 'dust-animation 0.5s ease-out forwards';
  
      // Append the particle to the rock
      rock.appendChild(particle);
  
      // Remove the particle after the animation is complete
      setTimeout(() => {
        rock.removeChild(particle);
      }, 500);
    }
  }


  function showScoreAnimation(x, y, score) {
    setAnimations(prev => [...prev, { id: Date.now(), type: 'score', x, y, score }]);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimations(prev => prev.slice(1));
    }, 1000);

    return () => clearTimeout(timer);
  }, [animations]);

  return (
    <div className="ore-mining-game" style={{backgroundImage: "url('/images/minigames/mining_background.jpg')"}}>
      <div className="game-info">
        <p>Score: {score}</p>
        <p>Time: {timeLeft}</p>
        <p>Difficulty: {difficulty}</p>
      </div>
      <table className="rock-grid">
        <tbody>
          {rocks.map((row, rowIndex) => (
            <tr key={rowIndex} className="rock-row">
              {row.map((rock, colIndex) => (
                <td key={`${rowIndex}-${colIndex}`} className="rock-cell">
                  <div
                    className={`rock ${rock.broken ? 'broken' : ''}`}
                    onClick={(e) => handleRockClick(rowIndex, colIndex, e)}
                  >
                    <img 
                      src={rock.broken 
                        ? '/images/minigames/broken_rock.png'
                        : '/images/minigames/rock.png'} 
                      alt={rock.broken ? "Broken rock" : "Rock"}
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {animations.map(animation => (
        <div
          key={animation.id}
          className="animation score"
          style={{
            left: `${animation.x}px`,
            top: `${animation.y}px`,
          }}
        >
          +{animation.score}
        </div>
      ))}
      {gameOver && <div className="game-over">Game Over!</div>}
    </div>
  );
};

export default OreMining;