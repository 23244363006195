import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setCurrentSkin } from '../redux/playerSlice';
import Modal from './Modal';
import './InventoryModal.css';

const hiddenItems = [
  'BlacksmithTrust', 
  'BlacksmithBelongings', 
  'SwordHilt', 
  'UnfinishedSmallSword', 
  'BowFrame',
  'Papyrus Scrolls of Power',
  'Desert Sand Crystal',
  'Mummy\'s Linen',
  'Sphinx\'s Riddle Stone',
  'Anubis Fang',
  'Ra\'s Sunstone',
  'Nile Lotus Essence',
  'Pharaoh\'s Gold',
  'Nile Crocodile Scales',
  'Scarab Beetle Essence'
];

const InventoryModal = ({ isOpen, onClose, onSkinChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const [localItems, setLocalItems] = useState({});
  const currentSkin = player.currentSkin || 'default';
  const ownedSkins = player.ownedSkins || ['default'];
  const [activeTab, setActiveTab] = useState('items');
  const [selectedSkin, setSelectedSkin] = useState(currentSkin);

  useEffect(() => {
    setLocalItems(player.items);
  }, [player.items]);

  const inventoryItems = Object.entries(localItems)
    .filter(([name, quantity]) => 
      quantity > 0 && 
      !ownedSkins.includes(name) && 
      !hiddenItems.includes(name)
    )
    .map(([name, quantity]) => ({ name, quantity }));

  const handleSetCurrentSkin = () => {
    dispatch(setCurrentSkin(selectedSkin));
    onSkinChange(selectedSkin);
    onClose();
  };

  const getSkinDisplayName = (skinId) => {
    return skinId.replace(/([A-Z])/g, ' $1').trim().replace(/lvl/g, ' Level ');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('inventory.title')}>
      <div className="inventory-modal">
        <div className="inventory-tabs">
          <button onClick={() => setActiveTab('items')}>{t('inventory.tabs.items')}</button>
          <button onClick={() => setActiveTab('skins')}>{t('inventory.tabs.skins')}</button>
        </div>
        
        {activeTab === 'items' && (
          <div className="inventory-items">
            {inventoryItems.length > 0 ? (
              inventoryItems.map((item) => (
                <div key={item.name} className="inventory-item">
                  <img
                    src={`/images/items/${item.name.toLowerCase().replace(' ', '-')}.png`}
                    alt={item.name}
                    className="item-icon"
                    onError={(e) => {
                      e.target.src = '/images/items/default.png';
                    }}
                  />
                  <span>{t('inventory.item', { name: item.name, quantity: item.quantity })}</span>
                </div>
              ))
            ) : (
              <p>{t('inventory.noItems')}</p>
            )}
          </div>
        )}
        
        {activeTab === 'skins' && (
          <div className="inventory-skins">
            {ownedSkins.map((skinId) => (
              <div key={skinId} className="skin-item">
                <label>
                  <input
                    type="radio"
                    name="skin-selection"
                    checked={selectedSkin === skinId}
                    onChange={() => setSelectedSkin(skinId)}
                  />
                  <span className="skin-name">{getSkinDisplayName(skinId)}</span>
                </label>
              </div>
            ))}
            <button className="set-skin-button" onClick={handleSetCurrentSkin}>
              {t('inventory.setSkinButton')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default InventoryModal;