import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCoins, spendCoins, addItem, spendItem, updateTotalScore } from '../redux/playerSlice';
import './Merchant.css';

const Merchant = ({ onClose, setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const [activeTab, setActiveTab] = useState('sell');
  const [legendaryEnhancementPrice, setLegendaryEnhancementPrice] = useState(1000000);
  const [isAvailable, setIsAvailable] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const itemPrices = {
    Trophy: 500,
    RoyalSeal: 1000,
    Stone: 50,
    Gem: 200,
    Fish: 100,
    Pearl: 300,
    AncientArtifact: 2000,
    TreasureMap: 1500,
    Rock: 30,
    Ore: 150,
    Wood: 80,
    Iron: 200,
    Silver: 400
  };

  useEffect(() => {
    const purchasedCount = player.items.LegendaryEnhancement || 0;
    const newPrice = 1000000 * Math.pow(1.5, purchasedCount);
    setLegendaryEnhancementPrice(Math.round(newPrice));
  }, [player.items.LegendaryEnhancement]);

  useEffect(() => {
    const calculateNextVisit = () => {
      const now = new Date();
      const nextWednesday = new Date();
      nextWednesday.setDate(now.getDate() + ((3 + 7 - now.getDay()) % 7));
      nextWednesday.setHours(15, 0, 0, 0);

      if (nextWednesday <= now) {
        nextWednesday.setDate(nextWednesday.getDate() + 7);
      }

      return nextWednesday;
    };

    const updateCountdown = () => {
      const now = new Date();
      const nextVisit = calculateNextVisit();
      const timeDiff = nextVisit - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
        setIsAvailable(false);
      } else {
        setTimeRemaining(null);
        setIsAvailable(true);
      }
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  const specialItems = [
    { 
      id: 'LegendaryEnhancement', 
      name: t('merchant.legendaryEnhancement'), 
      price: legendaryEnhancementPrice, 
      minLevel: 4 
    }
  ];

  const getSortedItems = () => {
    return Object.entries(player.items)
      .filter(([itemId, quantity]) => quantity > 0 && itemPrices[itemId])
      .sort((a, b) => b[1] - a[1]);
  };

  const getItemName = (itemId) => {
    const itemName = t(`items.${itemId}`, { ns: 'translation' });
    return itemName === `items.${itemId}` ? itemId : itemName;
  };

  const handleSellItem = (itemId) => {
    if (player.items[itemId] > 0) {
      const price = itemPrices[itemId];
      dispatch(spendItem({ id: itemId, quantity: 1 }));
      dispatch(addCoins(price));
      setAlertMessage(t('merchant.soldItem', { item: getItemName(itemId), price }));
    } else {
      setAlertMessage(t('merchant.noItemToSell', { item: getItemName(itemId) }));
    }
  };
  
  const handleBuyItem = (item) => {
    if (player.level < item.minLevel) {
      setAlertMessage(t('merchant.levelTooLow', { level: item.minLevel }));
      return;
    }

    if (player.coins < item.price) {
      setAlertMessage(t('merchant.notEnoughCoins'));
      return;
    }

    dispatch(spendCoins(item.price));
    dispatch(addItem({ id: item.id, quantity: 1 }));
    
    if (item.id === 'LegendaryEnhancement') {
      dispatch(updateTotalScore(player.totalScore + 500));
      setAlertMessage(t('merchant.boughtLegendaryEnhancement'));
      
      const newPrice = Math.round(item.price * 1.5);
      setLegendaryEnhancementPrice(newPrice);
    } else {
      setAlertMessage(t('merchant.boughtItem', { item: item.name }));
    }
  };

  return (
    <div className="merchant-modal-overlay">
      <div className="merchant-container">
        <button className="merchant-close-button" onClick={onClose}>&times;</button>
        <h2 className="merchant-title">{t('merchant.title')}</h2>
        
        {isAvailable ? (
          <>
            <div className="merchant-tabs">
              <button 
                className={`merchant-tab-button ${activeTab === 'sell' ? 'active' : ''}`}
                onClick={() => setActiveTab('sell')}
              >
                {t('merchant.sellItems')}
              </button>
              <button 
                className={`merchant-tab-button ${activeTab === 'buy' ? 'active' : ''}`}
                onClick={() => setActiveTab('buy')}
              >
                {t('merchant.buySpecialItems')}
              </button>
            </div>

            {activeTab === 'sell' && (
              <div className="merchant-sell-section">
                <p>{t('merchant.interestedInBuying')}</p>
                <div className="merchant-items-list">
                  {getSortedItems().map(([itemId, quantity]) => (
                    <div key={itemId} className="merchant-item-row">
                      <span>{getItemName(itemId)}: {itemPrices[itemId]} {t('general.coins')}</span>
                      <span>{t('merchant.youHave', { quantity })}</span>
                      <button 
                        className="merchant-button"
                        onClick={() => handleSellItem(itemId)}
                        disabled={quantity === 0}
                      >
                        {t('merchant.sellOne', { item: getItemName(itemId) })}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'buy' && (
              <div className="merchant-buy-section">
                <p>{t('merchant.specialItemsAvailable')}</p>
                <div className="merchant-items-list">
                  {specialItems.map((item) => (
                    <div key={item.id} className="merchant-item-row">
                      <span>{item.name}: {item.price} {t('general.coins')} ({t('merchant.levelRequired', { level: item.minLevel })})</span>
                      <button 
                        className="merchant-button"
                        onClick={() => handleBuyItem(item)}
                        disabled={player.level < item.minLevel || player.coins < item.price}
                      >
                        {t('merchant.buyItem', { item: item.name })}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="merchant-unavailable">
            <p>{t('merchant.notAvailable')}</p>
            {timeRemaining && (
              <div className="merchant-countdown">
                {t('merchant.nextVisitCountdown', {
                  days: timeRemaining.days,
                  hours: timeRemaining.hours,
                  minutes: timeRemaining.minutes,
                  seconds: timeRemaining.seconds
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Merchant;