import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './SmeltingPuzzle.css';

const Knob = ({ value, onChange, min, max, step, size = 200, color = '#007bff', label, isTemperature }) => {
  const { t } = useTranslation();
  const knobRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

  const valueToAngle = (val) => ((val - min) / (max - min)) * 360;
  const angleToValue = (angle) => {
    let normalizedAngle = (angle + 360) % 360;
    let value = (normalizedAngle / 360) * (max - min) + min;
    value = Math.round(value / step) * step;
    return clamp(value, min, max);
  };

  const handleInteraction = (clientX, clientY) => {
    const knob = knobRef.current;
    const rect = knob.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const angle = Math.atan2(clientY - centerY, clientX - centerX) * 180 / Math.PI;
    onChange(angleToValue(angle));
  };

  const handleStart = (event) => {
    setIsDragging(true);
    if (event.type === 'touchstart') {
      const touch = event.touches[0];
      handleInteraction(touch.clientX, touch.clientY);
    } else {
      handleInteraction(event.clientX, event.clientY);
    }
  };

  const handleMove = (event) => {
    if (!isDragging) return;
    event.preventDefault();
    if (event.type === 'touchmove') {
      const touch = event.touches[0];
      handleInteraction(touch.clientX, touch.clientY);
    } else {
      handleInteraction(event.clientX, event.clientY);
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchmove', handleMove, { passive: false });
    document.addEventListener('touchend', handleEnd);

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging]);

  const angle = valueToAngle(value);
  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className="knob-container">
      <div 
        ref={knobRef}
        className={`knob ${isTemperature ? 'temperature-knob' : ''}`}
        style={{ 
          width: `${size}px`, 
          height: `${size}px`,
          '--percentage': `${percentage}%`,
          '--color': color
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        <div className="knob-dial" style={{ transform: `rotate(${angle}deg)` }}>
          <div className="knob-indicator"></div>
        </div>
        <div className="knob-value">{value}</div>
      </div>
      <div className="knob-label">{t(label)}</div>
    </div>
  );
};

const SmeltingPuzzle = ({ difficulty = 'Medium', onComplete }) => {
  const { t } = useTranslation();
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(5);
  const [temperature, setTemperature] = useState(1000);
  const [time, setTime] = useState(30);
  const [currentOre, setCurrentOre] = useState(null);
  const [gameOver, setGameOver] = useState(false);

  const maxTemperature = 1700;
  const maxTime = 60;

  const ores = [
    { name: t('ores.iron'), idealTemp: 1538, idealTime: 30, tolerance: { temp: 50, time: 5 } },
    { name: t('ores.silver'), idealTemp: 961, idealTime: 20, tolerance: { temp: 30, time: 3 } },
    { name: t('ores.gold'), idealTemp: 1064, idealTime: 25, tolerance: { temp: 20, time: 2 } },
    { name: t('ores.copper'), idealTemp: 1084, idealTime: 28, tolerance: { temp: 40, time: 4 } },
    { name: t('ores.aluminum'), idealTemp: 660, idealTime: 15, tolerance: { temp: 25, time: 2 } }
  ];

  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    setCurrentOre(ores[Math.floor(Math.random() * ores.length)]);
  };

  const getDifficultyModifier = () => {
    switch (difficulty) {
      case 'Extra Hard': return 0.5;
      case 'Hard': return 0.75;
      case 'Medium': default: return 1;
    }
  };

  const handleSmelt = () => {
    if (gameOver) return;

    const difficultyModifier = getDifficultyModifier();
    const tempDiff = Math.abs(temperature - currentOre.idealTemp);
    const timeDiff = Math.abs(time - currentOre.idealTime);

    const tempTolerance = currentOre.tolerance.temp * difficultyModifier;
    const timeTolerance = currentOre.tolerance.time * difficultyModifier;

    if (tempDiff <= tempTolerance && timeDiff <= timeTolerance) {
      setScore(prevScore => prevScore + 100);
    }

    setAttempts(prevAttempts => prevAttempts - 1);

    if (attempts === 1) {
      setGameOver(true);
      onComplete(score / 100);
    } else {
      initializeGame();
    }
  };

  return (
    <div className="smelting-puzzle" style={{ '--temperature': `${temperature / maxTemperature * 100}%` }}>
      <div className="furnace">
        <h1>{t('smeltingPuzzle.title')}</h1>
        <p className="score">{t('smeltingPuzzle.score')}: {score / 100}</p>
        <p className="attempts">{t('smeltingPuzzle.attempts')}: {attempts}</p>
        <p className="current-ore">{t('smeltingPuzzle.smelt')}: {currentOre?.name}</p>

        <div className="controls">
          <Knob 
            value={temperature} 
            onChange={setTemperature} 
            min={0} 
            max={maxTemperature} 
            step={10} 
            color="#ff4136" 
            label="smeltingPuzzle.temperatureLabel"
            isTemperature={true}
          />
          <Knob 
            value={time} 
            onChange={setTime} 
            min={0} 
            max={maxTime} 
            step={1} 
            color="#0074d9" 
            label="smeltingPuzzle.timeLabel"
          />
        </div>

        <button onClick={handleSmelt} disabled={gameOver} className="smelt-button">
          {t('smeltingPuzzle.smeltButton')}
        </button>

        {gameOver && <p className="game-over">{t('smeltingPuzzle.gameOver')}</p>}
      </div>
    </div>
  );
};

export default SmeltingPuzzle;