import React, { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import styled from 'styled-components';

const GameContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GameTitle = styled.h2`
  color: #ffd700;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
`;

const HammerTime = ({ game, difficulty, onComplete }) => {
  const gameRef = useRef(null);
  const [gameStarted, setGameStarted] = useState(false);

  useEffect(() => {
    if (!gameStarted) return;

    let gameInstance;
    let score = 0;
    let combo = 0;
    let comboMultiplier = 1;
    let scoreText;
    let comboText;
    let timerText;
    let notes;
    let forges;
    let remainingTime = 30;
    let lanes = [90, 180, 270, 360, 450]; // Adjusted for 9:16 ratio

    function getDifficultySettings() {
      switch (difficulty) {
        case 'Extra Hard':
          return { spawnDelay: 300, noteSpeed: 500 };
        case 'Hard':
          return { spawnDelay: 400, noteSpeed: 400 };
        case 'Medium':
        default:
          return { spawnDelay: 500, noteSpeed: 300 };
      }
    }

    const difficultySettings = getDifficultySettings();

    function preload() {
      this.load.image('background', 'images/minigames/3d_background.png');
      this.load.image('note', 'images/minigames/3d_metal_piece.png');
      this.load.image('forge', 'images/minigames/3d_anvil.png');
      this.load.image('hammer', 'images/minigames/hammer.png');
      this.load.image('spark', 'images/minigames/spark.png');
      this.load.audio('hitSound', 'sounds/hit.mp3');
      this.load.audio('missSound', 'sounds/miss.mp3');
    }

    function create() {
      this.add.image(270, 480, 'background').setDisplaySize(540, 960);
      
      forges = this.physics.add.staticGroup();
      lanes.forEach(x => {
        let forge = forges.create(x, 800, 'forge');
        forge.setScale(0.6);
        forge.setInteractive();
        forge.on('pointerdown', () => this.clickAnvil(x));
      });

      notes = this.physics.add.group();
      
      scoreText = this.add.text(16, 16, 'Score: 0', { fontSize: '24px', fill: '#fff' });
      comboText = this.add.text(270, 56, 'Combo: 0x', { fontSize: '24px', fill: '#fff' });
      comboText.setOrigin(0.5, 0);
      timerText = this.add.text(430, 16, 'Time: 30', { fontSize: '24px', fill: '#fff' });

      this.hitSound = this.sound.add('hitSound');
      this.hitSound.setVolume(0.25);
      this.missSound = this.sound.add('missSound');
      this.missSound.setVolume(0.25);

      this.time.addEvent({ delay: difficultySettings.spawnDelay, callback: spawnNote, callbackScope: this, loop: true });
      this.time.addEvent({ delay: 1000, callback: updateTimer, callbackScope: this, loop: true });

      this.clickAnvil = clickAnvil.bind(this);
      this.hitNote = hitNote.bind(this);
      this.missNote = missNote.bind(this);
      this.increaseScore = increaseScore.bind(this);
      this.createSparkAnimation = createSparkAnimation.bind(this);
      this.increaseCombo = increaseCombo.bind(this);
      this.resetCombo = resetCombo.bind(this);
    }

    function update() {
      if (notes && notes.children) {
        notes.children.entries.forEach(note => {
          if (note.y > 850) {
            this.missNote(note);
            note.destroy();
          }
        });
      }
    }

    function clickAnvil(x) {
      let hitNote = false;
      notes.children.entries.forEach(note => {
        if (note.x === x && note.y > 700 && note.y < 820) {
          hitNote = true;
          this.hitNote(note);
        }
      });
      if (!hitNote) {
        this.missNote();
      }
    }

    function hitNote(note) {
      note.destroy();
      this.increaseScore(1);
      this.hitSound.play();
      this.createSparkAnimation(note.x, 760);
      this.increaseCombo();
    }

    function missNote() {
      this.missSound.play();
      this.resetCombo();
    }

    function increaseScore(points) {
      score += points * comboMultiplier;
      scoreText.setText('Score: ' + score);
    }

    function createSparkAnimation(x, y) {
      let spark = this.add.sprite(x, y, 'spark');
      spark.setScale(0.4);
      this.tweens.add({
        targets: spark,
        alpha: 0,
        scale: 1.2,
        duration: 300,
        onComplete: () => spark.destroy()
      });
    }

    function spawnNote() {
      const x = Phaser.Math.RND.pick(lanes);
      const note = notes.create(x, 0, 'note');
      note.setScale(0.5);
      note.setVelocityY(difficultySettings.noteSpeed);
    }

    function updateTimer() {
      remainingTime--;
      timerText.setText('Time: ' + remainingTime);
      if (remainingTime <= 0) {
        endGame.call(this);
      }
    }

    function endGame() {
      this.physics.pause();
      this.add.text(270, 480, 'Final Score: ' + score, { fontSize: '48px', fill: '#fff' }).setOrigin(0.5);
      onComplete(score);
    }

    function increaseCombo() {
      combo++;
      if (combo % 10 === 0) {
        comboMultiplier++;
      }
      comboText.setText(`Combo: ${combo}x${comboMultiplier}`);
    }

    function resetCombo() {
      combo = 0;
      comboMultiplier = 1;
      comboText.setText('Combo: 0x');
    }

    const config = {
      type: Phaser.AUTO,
      width: 580,
      height: 960,
      parent: gameRef.current,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
          debug: false
        }
      },
      scene: {
        preload: preload,
        create: create,
        update: update
      }
    };

    gameInstance = new Phaser.Game(config);

    return () => {
      if (gameInstance) {
        gameInstance.destroy(true);
      }
    };
  }, [difficulty, onComplete, gameStarted]);

  const handleStartGame = () => {
    setGameStarted(true);
  };

  return (
    <GameContainer>
      <GameTitle>{game.name}</GameTitle>
      {!gameStarted ? (
        <button onClick={handleStartGame}>Start Game</button>
      ) : (
        <div ref={gameRef} />
      )}
    </GameContainer>
  );
};

export default HammerTime;